import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chunk } from "lodash";
import { useSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { PermissionEvent, PermissionService } from "../../constants/permission";
import createRole from "../../actions/PermissionManagement/createRole.action";
import updateRole from "../../actions/PermissionManagement/updateRole.action";
import fetchRole from "../../actions/PermissionManagement/fetchRole.action";
import changePermissionStatus from "../../api/PermissionManagement/changePermissionStatus.service";
import "../../styles/permission/PermissionForm.scss";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { RootState } from "@/reducers";

interface IPermissionForm {
  open: boolean;
  roleId?: string;
  formEvent: "CREATE" | "EDIT";
  onClose(change: boolean): void;
}

interface IPermission {
  active: boolean;
  createdAt: string;
  event: string;
  _id: string;
  roleId: string;
  service: string;
  updatedAt: string;
}

interface ITabPanel {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IServicesToRemove {
  [key: string]: number[];
}

const objectDeepKeys = (obj: any, keys: string[] = []) => {
  for (const key of Object.keys(obj)) {
    if (typeof obj[key] !== "object" && obj[key] !== null) {
      keys.push(obj[key]);
    }
    if (typeof obj[key] === "object" && obj[key] !== null) {
      objectDeepKeys(obj[key], keys);
    }
  }
  return keys;
};

const TabPanel = (props: ITabPanel) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="w-full"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div className="h-[32rem]">{children}</div>}
    </div>
  );
};

export default function PermissionForm({
  open,
  roleId,
  onClose,
  formEvent,
}: IPermissionForm) {
  const dispatch = useDispatch();
  const { appData } = useSelector((state: RootState) => state);
  const { idToken, permissionManagement, permissionRules } = appData;

  const change = useRef<boolean>(false);
  const refFormEvent = useRef(formEvent);

  const { enqueueSnackbar } = useSnackbar();

  const [innerData] = useState<{ [key: string]: string[][] }>({});
  const [value, setValue] = useState(0);
  const [currentRole, setCurrentRole] = useState<string>("");

  const [rowName, setRowName] = useState<string>();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleChangePermissionStatus = (tableMeta: any) => {
    const index = innerData[tableMeta.rowData[1]].findIndex(
      (data) =>
        data[0] === tableMeta.rowData[0] && data[1] === tableMeta.rowData[1]
    );

    innerData[tableMeta.rowData[1]][index][2] === "Active"
      ? (innerData[tableMeta.rowData[1]][index][2] = "Inactive")
      : (innerData[tableMeta.rowData[1]][index][2] = "Active");

    changePermissionStatus({
      idToken,
      roleId: permissionManagement.role._id,
      event: tableMeta.rowData[0],
      service: tableMeta.rowData[1],
    })
      .then(() => {
        change.current = true;
        enqueueSnackbar(
          "the permission details has been successfully updated.",
          {
            variant: "success",
          }
        );
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data, {
          variant: "error",
        });
      });
  };

  const removeArrayElementsBySpecificIndexes = (
    array: string[][],
    indexesToRemove: number[]
  ) => {
    // sort indexesToRemove in descending order
    indexesToRemove.sort(function (a, b) {
      return b - a;
    });

    // remove elements from myArray at each index in indexesToRemove
    for (let i = 0; i < indexesToRemove.length; i++) {
      const index = indexesToRemove[i];
      array.splice(index, 1);
    }

    return array;
  };

  const hideUnusedEvents = (service: string, arrayToRemove: string[][]) => {
    const eventIndexes = {
      CREATE: 0,
      READ: 1,
      UPDATE: 2,
      DELETE: 3,
    };

    const servicesToRemove: IServicesToRemove = {
      OwnHospitalReferral: [eventIndexes.CREATE, eventIndexes.DELETE],
      ChildHospitalReferral: [eventIndexes.CREATE, eventIndexes.DELETE],
      AnyHospitalReferral: [eventIndexes.CREATE, eventIndexes.DELETE],
      OwnHospitalReport: [
        eventIndexes.CREATE,
        eventIndexes.UPDATE,
        eventIndexes.DELETE,
      ],
      ChildHospitalReport: [
        eventIndexes.CREATE,
        eventIndexes.UPDATE,
        eventIndexes.DELETE,
      ],
      AnyHospitalReport: [
        eventIndexes.CREATE,
        eventIndexes.UPDATE,
        eventIndexes.DELETE,
      ],
      OnlineStatus: [
        eventIndexes.CREATE,
        eventIndexes.UPDATE,
        eventIndexes.DELETE,
      ],
      OwnUser: [eventIndexes.CREATE, eventIndexes.READ, eventIndexes.DELETE],
      OwnHospitalLoginHistory: [
        eventIndexes.CREATE,
        eventIndexes.UPDATE,
        eventIndexes.DELETE,
      ],
      ChildHospitalLoginHistory: [
        eventIndexes.CREATE,
        eventIndexes.UPDATE,
        eventIndexes.DELETE,
      ],
      AnyHospitalLoginHistory: [
        eventIndexes.CREATE,
        eventIndexes.UPDATE,
        eventIndexes.DELETE,
      ],
      UserMember: [eventIndexes.READ, eventIndexes.UPDATE, eventIndexes.DELETE],
      MophRefer: [eventIndexes.CREATE, eventIndexes.DELETE],
    };

    return servicesToRemove[service]
      ? removeArrayElementsBySpecificIndexes(
          arrayToRemove,
          servicesToRemove[service]
        )
      : arrayToRemove;
  };

  const hideUnusedServices = (services: string[][]) => {
    const hideServices = [
      PermissionService.MANAGEMENT.USER_MANAGEMENT.CHILD_HOSPITAL_USER,
      PermissionService.MANAGEMENT.HOSPITAL_MANAGEMENT,
      PermissionService.KNOWLEDGE_GRAPH,
      PermissionService.POPULATION_HEALTH,
    ];

    const superAdminServices = [
      PermissionService.REFERRAL.ANY_HOSPITAL_REFERRAL,
      PermissionService.MANAGEMENT.USER_MANAGEMENT.ANY_HOSPITAL_USER,
      PermissionService.REPORT.ANY_HOSPITAL_REPORT,
      PermissionService.LOGIN_HISTORY.ANY_HOSPITAL_LOGIN_HISTORY,
    ];

    const isAnyHospitalUser = permissionRules?.find(
      (rule: any) =>
        rule.subject ===
        PermissionService.MANAGEMENT.USER_MANAGEMENT.ANY_HOSPITAL_USER
    );

    if (!isAnyHospitalUser) hideServices.push(...superAdminServices);

    // show sample data service if domain is Ever hospital
    if (
      location.host !== "refer.hie-ever-dev.everapp.io" &&
      location.host !== "refer.hie-ever.everapp.io"
    ) {
      hideServices.push("SampleData");
    }

    const indexs: number[] = [];
    services.forEach((service: string[], index: number) => {
      if (hideServices.includes(service[0])) {
        indexs.push(index);
      }
    });

    // sort indexesToRemove in descending order
    indexs.sort(function (a, b) {
      return b - a;
    });

    // remove elements from myArray at each index in indexesToRemove
    for (let i = 0; i < indexs.length; i++) {
      const idx = indexs[i];
      services.splice(idx, 1);
    }

    return services;
  };

  const renderExpandableRow = (rowData: string[]) => {
    const innerColumns = [
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: string, tableMeta: any) => {
            const thisService = innerData[tableMeta.rowData[1]].find(
              (data) =>
                data[0] === tableMeta.rowData[0] &&
                data[1] === tableMeta.rowData[1]
            );

            return (
              <div>
                {permissionManagement.role && (
                  <Checkbox
                    defaultChecked={thisService && thisService[2] === "Active"}
                    onClick={() => {
                      handleChangePermissionStatus(tableMeta);
                    }}
                  />
                )}
                {value}
              </div>
            );
          },
        },
      },
      {
        name: "Service",
        options: {
          display: false,
        },
      },
    ];

    const innerOptions: MUIDataTableOptions = {
      responsive: "standard",
      filter: false,
      print: false,
      search: false,
      viewColumns: false,
      expandableRowsHeader: false,
      download: false,
      elevation: 0,
      pagination: false,
      selectableRows: "none",
    };

    return (
      <TableRow className="inner-table">
        <TableCell colSpan={rowData.length + 1}>
          {Object.keys(innerData).length > 0 && (
            <MUIDataTable
              title=""
              data={innerData[rowData[0]]}
              columns={innerColumns}
              options={innerOptions}
            />
          )}
        </TableCell>
      </TableRow>
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCreateOrUpdateRole = () => {
    if (currentRole) {
      if (permissionManagement.role) {
        dispatch(
          updateRole({
            idToken,
            roleId: permissionManagement.role._id,
            roleName: currentRole,
          })
        );
      } else {
        refFormEvent.current = "EDIT";
        dispatch(createRole({ idToken, role: currentRole }));
      }

      change.current = true;
    }

    setConfirmDialogOpen(false);
  };

  const columns = [
    {
      name: "Service",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const data = hideUnusedServices(chunk(objectDeepKeys(PermissionService), 1));

  const options: MUIDataTableOptions = {
    responsive: "standard",
    filter: false,
    selectableRows: "none",
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    expandableRows: !!permissionManagement.role && !!currentRole,
    expandableRowsHeader: false,
    rowsPerPage: 6,
    rowsPerPageOptions: [6],
    count: data ? data.length : 0,
    renderExpandableRow,
    onRowExpansionChange(currentRowsExpanded: any[]) {
      setRowName(data[currentRowsExpanded[0].index][0]);
    },
  };

  useEffect(() => {
    change.current = false;
  }, []);

  useEffect(() => {
    if (permissionManagement.role && refFormEvent.current === "EDIT") {
      setCurrentRole(permissionManagement.role.role);
    } else {
      setCurrentRole("");
    }
  }, [permissionManagement.role]);

  useEffect(() => {
    if (roleId) {
      dispatch(fetchRole({ idToken, roleId }));
    }
  }, [roleId]);

  useEffect(() => {
    if (rowName) {
      const data = hideUnusedEvents(
        rowName,
        chunk(Object.keys(PermissionEvent), 1).map((event) => {
          const thisService = permissionManagement.role.permission.find(
            (service: IPermission) =>
              service.event === event[0] && service.service === rowName
          );

          return [
            ...event,
            rowName,
            thisService && thisService.active ? "Active" : "Inactive",
          ];
        })
      );

      if (!Object.prototype.hasOwnProperty.call(innerData, rowName)) {
        innerData[rowName] = data;
      }
    }
  }, [rowName]);

  return (
    <>
      <Dialog
        fullWidth
        id="service-permission-form"
        maxWidth="lg"
        open={open}
        onClose={() => onClose(change.current)}
      >
        <DialogTitle className="bg-gray-100">
          {permissionManagement.role ? "Edit role" : "Create role"}
        </DialogTitle>
        <DialogContent className="flex flex-grow !p-0">
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className="border-r w-52"
          >
            <Tab className="!items-start" label="Role detail" />
            <Tab className="!items-start" label="Service" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <div className="h-full grid grid-cols-2">
              <div className="p-12">
                <div className="flex flex-col p-3 justify-center items-center rounded-lg border min-h-full">
                  <TextField
                    fullWidth
                    value={currentRole}
                    label="Role name"
                    placeholder="Role name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setCurrentRole(e.target.value)}
                  />

                  <Button
                    fullWidth
                    variant="contained"
                    className="!mt-4"
                    onClick={() => setConfirmDialogOpen(true)}
                  >
                    {permissionManagement.role ? "Edit role" : "Create role"}
                  </Button>
                </div>
              </div>
              <div className="absolute block top-32 right-20 h-20 w-20 bg-blue-200 rounded-full shadow-lg bg-[url('../src/assets/images/townImage.svg')]" />
              <div className="absolute block top-48 right-64 h-28 w-28 bg-green-200 rounded-full shadow-lg bg-[url('../src/assets/images/townImage.svg')]" />
              <div className="absolute block top-80 right-96 h-12 w-12 bg-blue-200 rounded-full shadow-lg bg-[url('../src/assets/images/townImage.svg')]" />
              <div className="absolute block top-72 right-20 h-44 w-44 bg-blue-200 rounded-full shadow-lg bg-[url('../src/assets/images/townImage.svg')]" />
              <div className="absolute block top-56 right-48 h-8 w-8 bg-blue-200 rounded-full shadow-lg bg-[url('../src/assets/images/townImage.svg')]" />
              <div className="absolute block bottom-12 right-80 h-24 w-24 bg-green-200 rounded-full shadow-lg bg-[url('../src/assets/images/townImage.svg')]" />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="p-3 h-full justify-center items-center">
              <MUIDataTable
                title={
                  permissionManagement.role && currentRole
                    ? permissionManagement.role.role
                    : "Please fill role detail before grant permission"
                }
                data={data}
                columns={columns}
                options={options}
              />
            </div>
          </TabPanel>
        </DialogContent>
      </Dialog>

      {/* confirm create/update role name */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm to apply changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to apply this changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={() => setConfirmDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleCreateOrUpdateRole}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
