import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { Print } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Table,
  TableBody,
  TableHead,
  Grid,
  Button,
  TablePagination,
  Paper,
  Hidden,
} from "@mui/material";
import { PAYER_REJECTED, STARTED } from "../../constants/invoiceStatus";
import { Container, TableRow, TableCell, Status, ContentAlert } from "./styles";

// pdf
import printSS from "../../assets/pdf/genSS";
import printUC from "../../assets/pdf/genUC";
import printAliens from "../../assets/pdf/genAliens";

// action
import {
  actionInvoicesDetail,
  actionAcceptInvoice,
  actionRejectInvoice,
  actionsCancelInvoice,
} from "../../actions/InvoiceAction";

// functions
import {
  getBillingStatusConfig,
  getInvoiceStatusTranslate,
  hospDataFromHospCode,
} from "../../functions/FuncPerjer";
import {
  IFunctionPerjer,
  IInvoiceDetail,
  IInvoiceSchema,
  UseParamsType,
} from "./type";

function InvoiceDetail() {
  const { invoiceId, categoryId, provider, payer } = useParams<UseParamsType>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const { appData, invoices }: any = useSelector((state) => state);
  const { idToken, hospCode, loginData } = appData;
  const isProvider = history.location.pathname.indexOf("/to") !== -1;
  const providerCancel =
    isProvider && invoices?.invoiceResult?.status === PAYER_REJECTED;
  const payerAcceptOrReject =
    !isProvider && invoices?.invoiceResult?.status === STARTED;
  const [open, setOpen] = useState(false);
  const fetchData = () => {
    const params = {
      idToken,
      invoiceId,
      payer,
      provider,
      categoryId,
      page: page + 1,
      limit: rowsPerPage,
    };
    dispatch(actionInvoicesDetail(params));
  };
  useEffect(() => {
    fetchData();
  }, [idToken, hospCode, loginData, page, rowsPerPage]);

  const onClickAccept = () => {
    const body = {
      idToken,
      invoiceId,
      payer,
      provider,
      categoryId,
    };
    dispatch(actionAcceptInvoice(body));
    setOpen(true);
  };
  const onClickReject = () => {
    const body = {
      idToken,
      invoiceId,
      payer,
      provider,
      categoryId,
    };
    dispatch(actionRejectInvoice(body));
    setOpen(true);
  };
  const onClickCancel = () => {
    const body = {
      idToken,
      invoiceId,
      payer,
      provider,
      categoryId,
    };
    dispatch(actionsCancelInvoice(body));
    setOpen(true);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onClickToPatientInvoice = (visitId: string) => {
    history.push(`/billing-patient/${visitId}/${categoryId}`);
  };
  const onCloseAuto = () => {
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };
  const localStringPrice = (p: number) =>
    p?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  const checkCategory = (data: IInvoiceSchema) => {
    const datepdf = new Date(`${data.year}-${data.month}`);
    const { hospName }: IFunctionPerjer | any = hospDataFromHospCode(
      data.payer
    );
    switch (data.categoryName?.toUpperCase()) {
      // บัตรทอง
      case "UC":
      case "UC1":
      case "UC 3":
      case "UC500":
      case "UC700":
      case "5A4534CF-937F-452C-A21A-686CCCCB8AF1":
      case "AE1F8BFC-E924-4B5F-B901-7299368CDE57":
      case "9EED58DB-DDB6-4425-B58F-1478DBDE49BB":
      case "0DF4D320-5A07-40BD-82A1-A7450B8F6881":
        return printUC(data, hospName, datepdf, data.provider);
      // ต่างด้าว
      case "AL":
        return printAliens(data, hospName, datepdf, data.provider);
      // ประกันสังคม
      case "SS":
      case "SSO":
      case "3F51DCFD-9174-4FF4-B5EF-E5B5DFD1EA4A":
        return printSS(data, hospName, datepdf, data.provider);
      default:
        console.log(`data.category of "${data.categoryName}" does not matched`);
        printSS(data, hospName, datepdf, data.provider);
    }

    return false;
  };

  const textHeader = isProvider
    ? `เรียกเก็บ รพ. ${invoices?.invoiceResult?.hospNamePayer}`
    : `ตามจ่าย รพ. ${invoices?.invoiceResult?.hospNameProvider}`;

  return (
    <Container className="!mt-20">
      <div className="header">
        <EventNoteIcon className="header-icon" />
        <h5 className="title">รายละเอียด อินวอยซ์</h5>
      </div>

      <Grid>
        <ContentAlert>
          {open && invoices?.fetchStatus === 200 && (
            <Alert severity="success" variant="filled">
              <AlertTitle>Success</AlertTitle>
              {invoices?.message}
              {onCloseAuto()}
            </Alert>
          )}
          {open && invoices?.fetchStatus === 400 && (
            <Alert severity="error" variant="filled">
              <AlertTitle>Error</AlertTitle>
              {invoices?.message}
              {onCloseAuto()}
            </Alert>
          )}
        </ContentAlert>
        <Container>
          <Grid container justifyContent="center">
            <Hidden lgDown>
              <Grid item xs={12} container justifyContent="flex-end">
                <Status
                  color={
                    getInvoiceStatusTranslate(invoices?.invoiceResult?.status)
                      ?.color
                  }
                >
                  {`สถานะ: ${
                    getInvoiceStatusTranslate(invoices?.invoiceResult?.status)
                      ?.text || ""
                  }`}
                </Status>
              </Grid>
            </Hidden>
            <Grid item xs={12} container>
              <div style={{ marginLeft: "10%" }}>
                {textHeader}
                &emsp;&emsp;
                <Hidden lgDown>
                  {`ประจำเดือน: ${new Date(
                    `${invoices?.invoiceResult?.year}-${invoices?.invoiceResult?.month}`
                  )?.toLocaleDateString("th-TH", {
                    month: "short",
                    year: "numeric",
                  })}`}
                </Hidden>
                &emsp;&emsp;
                <Hidden lgDown>{`หมายเลข อินวอยซ์: ${
                  invoices?.invoiceResult?.id || ""
                }`}</Hidden>
                <Hidden lgDown>
                  <div>
                    {`จำนวนผู้ป่วย: ${invoices?.invoiceResult?.totalBills} ราย `}
                    &emsp;&emsp;&emsp;&emsp;
                    {`ยอดเงินตามจริง: ${localStringPrice(
                      invoices?.invoiceResult?.actualTotal
                    )} บาท `}
                    &emsp;&emsp;&emsp;&emsp;
                    {`ยอดที่คำนวณ: ${localStringPrice(
                      invoices?.invoiceResult?.calculatedTotal
                    )} บาท `}
                    &emsp;&emsp;&emsp;&emsp;
                    {` ยอดที่เรียกเก็บ: ${localStringPrice(
                      invoices?.invoiceResult?.finalTotal
                    )} บาท `}
                  </div>
                </Hidden>
                <Hidden mdUp>
                  <p>
                    {`ประจำเดือน: ${new Date(
                      `${invoices?.invoiceResult?.year}-${invoices?.invoiceResult?.month}`
                    )?.toLocaleDateString("th-TH", {
                      month: "short",
                      year: "numeric",
                    })}`}
                  </p>
                  <p>{`หมายเลข invoice: ${
                    invoices?.invoiceResult?.id || ""
                  }`}</p>
                  <p>{`ยอดเงินตามจริง: ${localStringPrice(
                    invoices?.invoiceResult?.actualTotal
                  )} บาท`}</p>
                  <p>{`ยอดที่คำนวณ: ${localStringPrice(
                    invoices?.invoiceResult?.calculatedTotal
                  )} บาท`}</p>
                  <p>{`ยอดที่เรียกเก็บ: ${localStringPrice(
                    invoices?.invoiceResult?.finalTotal
                  )} บาท`}</p>
                </Hidden>
                <Hidden mdUp>
                  <Status
                    color={
                      getInvoiceStatusTranslate(invoices?.invoiceResult?.status)
                        ?.color
                    }
                  >
                    {`สถานะ: ${
                      getInvoiceStatusTranslate(invoices?.invoiceResult?.status)
                        ?.text || ""
                    }`}
                  </Status>
                </Hidden>
              </div>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <div>
                {providerCancel && (
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={
                      invoices?.invoiceResult?.status !== "PAYER_REJECTED"
                    }
                    onClick={onClickCancel}
                  >
                    Cancel
                  </Button>
                )}
                <div style={{ margin: "10px" }}>
                  {payerAcceptOrReject && (
                    <>
                      <Button
                        variant="contained"
                        onClick={onClickAccept}
                        style={{
                          margin: "5px",
                          backgroundColor: "#396EB0",
                          color: "whitesmoke",
                        }}
                      >
                        ยอมรับ
                      </Button>
                      <Button
                        variant="contained"
                        onClick={onClickReject}
                        style={{
                          backgroundColor: "#D83A56",
                          color: "whitesmoke",
                        }}
                      >
                        ปฏิเสธ
                      </Button>
                    </>
                  )}
                  <Button
                    onClick={() => checkCategory(invoices?.invoiceResult)}
                  >
                    <Print color="action" fontSize="medium" />
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">วันที่</TableCell>
            <TableCell align="center">CID</TableCell>
            <TableCell align="center">ชื่อ</TableCell>
            <TableCell align="right">ยอดเงินตามจริง (฿)</TableCell>
            <TableCell align="right">ยอดที่คำนวณ (฿)</TableCell>
            <TableCell align="right">ยอดที่เรียกเก็บ (฿)</TableCell>
            <TableCell align="right">สถานะ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices && invoices?.docs?.length > 0 ? (
            invoices?.docs?.map((row: IInvoiceDetail) => (
              <TableRow
                key={row?.id || row.categoryId}
                onClick={() => onClickToPatientInvoice(row?.visitId)}
              >
                <TableCell align="center" component="th" scope="row">
                  {new Date(row?.visitDateTime)?.toLocaleDateString("th-TH", {
                    month: "short",
                    year: "numeric",
                    day: "numeric",
                  })}
                </TableCell>
                <TableCell align="center">
                  {Array.isArray(row?.patient) && row?.patient[0]?.cid}
                </TableCell>
                <TableCell align="center">
                  {Array.isArray(row?.patient) &&
                    `${row?.patient[0]?.data?.fullname?.title} ${row?.patient[0]?.data?.fullname?.firstName} ${row?.patient[0]?.data?.fullname?.lastName}`}
                </TableCell>
                <TableCell align="right">
                  {row?.actualTotal?.toLocaleString("en-EN")}
                </TableCell>
                <TableCell align="right">
                  {row?.calculatedTotal?.toLocaleString("en-EN")}
                </TableCell>
                <TableCell align="right">
                  {row?.finalTotal?.toLocaleString("en-EN")}
                </TableCell>
                <TableCell align="right">
                  <Status color={getBillingStatusConfig(row.status)?.color}>
                    {getBillingStatusConfig(row?.status)?.text}
                  </Status>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center" component="th" scope="row">
                ไม่พบข้อมูล
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={invoices?.totalDocs || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Container>
  );
}

export default InvoiceDetail;
