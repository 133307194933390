import { RootState } from "../../reducers";
import { useState } from "react";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MyHospFilter from "./components/MyHospFilter";
import { Button, TextField } from "@mui/material";
import { IDoctor, TClinic } from "@/types/clinic";
import { Search } from "@mui/icons-material";
import { IReferral } from "../../types/refers";
import ReferCard from "../Refers/ReferCard";
import fetchSchedules from "../../api/AppointmentOPD/fetchSchedule";
import exportExcel from "../../api/AppointmentOPD/exportExcel";
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import { ThaiDate } from "../../functions/FuncDateTimes";
import Box from "@mui/material/Box";

function HospitalAppointment() {
  const { appData } = useSelector((state: RootState) => state);
  const {
    idToken,
    loginData: { hospCode },
  } = appData;
  const [dateRange, setDateRange] = useState<DateRange<Date>>([
    new Date(),
    new Date(),
  ]);
  const [selectedDoctor, setSelectedDoctor] = useState<IDoctor | null>(null);
  const [selectedClinic, setSelectedClinic] = useState<TClinic>();
  const [refers, setRefers] = useState<IReferral[]>([]);
  const handleSelectedClinic = (clinic: TClinic) => setSelectedClinic(clinic);
  const handleSelectedDoctor = (doctor: IDoctor) => setSelectedDoctor(doctor);

  const handleFetchSchedules = async (hospCode: string, doctorId?: string) => {
    try {
      const [start, end] = dateRange;
      if (!doctorId || !hospCode || !selectedClinic?._id || !start || !end) {
        return;
      }

      const clinicId = selectedClinic._id;
      const startDate = new Date(start);
      const endDate = new Date(end);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      const data = await fetchSchedules(
        idToken,
        doctorId,
        hospCode,
        clinicId,
        startDate,
        endDate,
        "VERIFY"
      );

      const refersData = data
        .filter((a) => a.referrals.length > 0 && a.clinicId === clinicId)
        .map((a) => a.referrals)
        .flat();
      setRefers(refersData);
    } catch (error) {
      console.log("fetch schedules error", error);
    }
  };

  const onDownloadExcel = async () => {
    const [start, end] = dateRange;
    if (refers?.length === 0 || !start || !end || !selectedDoctor) {
      return;
    }
    const referList = refers.map((refer) => refer._id);
    const json = await exportExcel(idToken, referList);
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const strStartDate = new Date(start).toLocaleDateString("en-GB");
    const strEndDate = new Date(end).toLocaleDateString("en-GB");
    const fileExtension = ".xlsx";
    const fileName = `${selectedDoctor._id}_${strStartDate}_${strEndDate}`;
    const ws = utils.json_to_sheet(json);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
  };

  const refersCardItem = (refers: IReferral[]) => {
    return refers.map((refer) =>
      refer.appointments?.map((appointment, index) => (
        <Box key={index}>
          <Box className="flex mx-2 h-8 items-center justify-between">
            <span className="text-gray-500">
              วันที่แพทย์ {ThaiDate(appointment.scheduleDateTime)}
            </span>
          </Box>
          <ReferCard referral={refer} avatarText={index} />
        </Box>
      ))
    );
  };

  return (
    <div className="grid mt-14">
      <div className="grid grid-cols-2 gap-4 bg-white p-6">
        <div>
          <MyHospFilter
            token={idToken}
            hospCode={hospCode}
            onSelectedDoctor={handleSelectedDoctor}
            onSelectedClinic={handleSelectedClinic}
          />
        </div>
        <div className="flex">
          <DateRangePicker
            value={dateRange}
            inputFormat="DD/MM/YYYY"
            onChange={(date) => setDateRange(date)}
            renderInput={(startProps, endProps) => (
              <>
                <TextField id="start-date" {...startProps} />
                <TextField
                  {...endProps}
                  id="end-date"
                  InputProps={{
                    endAdornment: (
                      <CalendarTodayIcon className="text-gray-500" />
                    ),
                  }}
                />
              </>
            )}
          />
          <Button
            variant="contained"
            onClick={() => handleFetchSchedules(hospCode, selectedDoctor?._id)}
            disabled={!selectedDoctor}
            sx={{
              bgcolor: "white",
              color: "gray",
              borderRadius: "20px",
              mx: 2,
              p: 2,
              ":hover": {
                backgroundColor: "#fff",
                color: "#132043",
                border: "0.5px solid",
              },
            }}
          >
            <Search />
          </Button>
          <Button
            onClick={onDownloadExcel}
            disabled={refers.length === 0}
            variant="contained"
            sx={{
              bgcolor: "white",
              color: "gray",
              borderRadius: "20px",
              mx: 2,
              p: 2,
              ":hover": {
                backgroundColor: "#fff",
                color: "#132043",
                border: "0.5px solid",
              },
            }}
          >
            ดาวน์โหลด
          </Button>
        </div>
      </div>

      <div
        className="grid gap-4 p-4 bg-gray-100
            md:grid-cols-2
            lg:grid-cols-3"
      >
        {refersCardItem(refers)}
      </div>
    </div>
  );
}

export default HospitalAppointment;
