import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

const cancelAppointment = async (
  token: string,
  referId: string,
  appointmentId: string,
  scheduleId: string
) => {
  const url = getApiUrl();
  await axios.put(
    `${url}/v1/schedule/cancel/appointment`,
    { referId, appointmentId, scheduleId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export default cancelAppointment;
