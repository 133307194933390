import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DateTimePicker from "@mui/lab/DateTimePicker";
import DialogTitle from "@mui/material/DialogTitle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { REFER_IN, REFER_BACK } from "../../../../constants/referType";

export default function ReferAcceptDialog(props) {
  const { handleSubmit, control } = useForm();

  const [referType, setReferType] = useState(false);

  const { open, cid, ptname, hospName, onClose, onSubmit } = props;

  const submit = (referData) => {
    let appointmentInfo = {};

    if (referType === REFER_IN) {
      appointmentInfo = referData;
    }

    onSubmit(appointmentInfo);
  };

  return (
    <Dialog
      fullWidth
      sx={{ "& .MuiDialog-paper": { borderRadius: "16px" } }}
      maxWidth="xs"
      open={open}
      onClose={() => onClose()}
    >
      <form onSubmit={handleSubmit((e) => submit(e))}>
        <DialogTitle>
          <CheckCircleIcon className="bg-green-100 p-1 rounded-full text-green-500 mr-2 w-8 h-8" />
          ยืนยันการรับ Refer
          <IconButton
            className="float-right"
            onClick={() => onClose()}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {cid && <DialogContentText>CID : {cid}</DialogContentText>}
          {ptname && <DialogContentText>ผู้ป่วย : {ptname}</DialogContentText>}
          {hospName && <DialogContentText>จาก : {hospName}</DialogContentText>}

          <Controller
            as={
              <DateTimePicker
                renderInput={(inputProps) => (
                  <TextField
                    fullWidth
                    className="!mt-5"
                    margin="dense"
                    size="small"
                    {...inputProps}
                  />
                )}
                label="วัน / เวลา นัดหมาย"
                InputProps={{
                  endAdornment: <CalendarTodayIcon className="text-gray-500" />,
                }}
                onChange={() => {
                  return;
                }}
              />
            }
            defaultValue={new Date()}
            control={control}
            name="appointmentDateTime"
          />

          <Controller
            as={
              <TextField
                fullWidth
                margin="dense"
                required={referType === REFER_IN}
                label="จุดนัดหมาย"
                variant="outlined"
                size="small"
              />
            }
            control={control}
            name="appointmentPoint"
          />

          <Controller
            as={
              <TextField
                fullWidth
                multiline
                margin="dense"
                label="หมายเหตุ"
                variant="outlined"
                size="small"
              />
            }
            control={control}
            name="appointmentNotes"
          />

          <Button
            fullWidth
            id={REFER_IN}
            type="submit"
            size="small"
            className="!bg-emerald-500 !text-white !mt-3
              hover:!bg-emerald-600"
            onClick={(e) => setReferType(e.currentTarget.id)}
          >
            รับ Refer
          </Button>

          <Button
            fullWidth
            id={REFER_BACK}
            type="submit"
            size="small"
            className="!bg-sky-500 !text-white !mt-3
              hover:!bg-sky-600"
            onClick={(e) => setReferType(e.currentTarget.id)}
          >
            รับ Refer Back
          </Button>

          <Button
            fullWidth
            id={REFER_BACK}
            size="small"
            className="!mt-3 !text-sky-600
            hover:!bg-transparent"
            onClick={() => onClose()}
          >
            ยกเลิก
          </Button>
        </DialogContent>
      </form>
    </Dialog>
  );
}

ReferAcceptDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  cid: PropTypes.string.isRequired,
  ptname: PropTypes.string.isRequired,
  hospName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
