import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Drawer,
  Divider,
  Typography,
  ListItemText,
  ListItemButton,
  Select,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { fDate } from "../../../functions/dayJs";
import { IReferral } from "../../../types/refers";
import { TSchedule } from "../../../types/schedule";
import { COMPLETE, PENDING, REJECTED } from "../../../constants/referralStatus";
import { shortHospName } from "../../../functions/FuncPerjer";
import AppointmentAcceptDialog from "./DialogAccepted";
import acceptRefer from "../../../api/acceptRefer.service";
import rejectRefer from "../../../api/rejectRefer.service";
import Swal from "sweetalert2";
import AppointmentRejectDialog from "./DialogRejected";
import doctorDayoffSchedule from "../../../api/AppointmentOPD/doctorDayoff";
import { MoreVert } from "@mui/icons-material";
import fetchScheduleById from "../../../api/AppointmentOPD/fetchScheduleById";

type TAppointmentDrawer = {
  open: boolean;
  onHandleDrawer(status: boolean): void;
  scheduleId: string;
  token: string;
  isDoctor: boolean;
};
function AppointmentDrawer({
  open,
  onHandleDrawer,
  scheduleId,
  token,
  isDoctor,
}: TAppointmentDrawer) {
  const status = [PENDING, COMPLETE, REJECTED];
  const [event, setEvent] = useState<TSchedule>();
  const [appointments, setAppointments] = useState<IReferral[]>([]);
  const [hospitals, setHospitals] = useState<string[]>([]);
  const [acceptDialogOpen, setAcceptDialogOpen] = useState<boolean>(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState<boolean>(false);
  const [selectedReferral, setSelectedReferral] = useState<IReferral | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    refer: IReferral
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedReferral(refer);
  };

  const handleOpenAccept = () => setAcceptDialogOpen(true);

  const handleCloseAccept = () => {
    setAcceptDialogOpen(false);
    setSelectedReferral(null);
  };

  const handleOpenReject = () => setRejectDialogOpen(true);

  const handleCloseReject = () => {
    setRejectDialogOpen(false);
    setSelectedReferral(null);
  };

  const colorRefer = (status: string) => {
    if (status === REJECTED) return "#feeceb";
    if (status === COMPLETE) return "#edf7ed";
    return "";
  };

  const groupFromHospCode = (referrals: IReferral[]) => {
    const fromHospData: string[] = [];
    for (let index = 0; index < referrals.length; index++) {
      const referral = referrals[index];
      if (!fromHospData.includes(referral.data.fromHospCode))
        fromHospData.push(referral.data.fromHospCode);
    }
    setHospitals(fromHospData);
  };

  const filterStatus = (status: string | unknown) => {
    if (!event) return;
    if (status === "all") {
      setAppointments(event.referrals);
      return;
    }
    const filterStatusAppointment = event.referrals.filter(
      (refer) => refer.status === status
    );
    setAppointments(filterStatusAppointment);
  };

  const filterHospital = (hospCode: string | unknown) => {
    if (!event) return;
    if (hospCode === "all") {
      setAppointments(event.referrals);
      return;
    }
    const filterHospAppointment = event.referrals.filter(
      (refer) => refer.data.fromHospCode === hospCode
    );
    setAppointments(filterHospAppointment);
  };

  const onAcceptAppointment = async (
    appointmentPoint: string,
    appointmentNotes: string
  ) => {
    try {
      if (!event) return;
      if (!selectedReferral) throw Error("selected appointment");

      const bid = selectedReferral.bid;
      const appointmentInfo = {
        appointmentPoint,
        appointmentNotes,
        appointmentDateTime: event.start,
      };

      await acceptRefer(token, bid, appointmentInfo);
      handleCloseAccept();
      await onFetchScheduleById(scheduleId);
      Swal.fire("Saved!", "", "success");
    } catch (error) {
      Swal.fire("Saved!", "", "error");
    }
  };

  const onRejectAppointment = async (reason: string) => {
    try {
      if (!selectedReferral) throw Error("selected appointment");
      await rejectRefer(token, selectedReferral.bid, reason);
      handleCloseReject();
      await onFetchScheduleById(scheduleId);
      Swal.fire("Saved!", "", "success");
    } catch (error) {
      Swal.fire("Saved!", "", "error");
    }
  };

  const handleDoctorDayOff = async () => {
    try {
      if (!event) return;
      if (event.dayOff) {
        Swal.fire("Saved!", "", "success");
        return;
      }
      const { isConfirmed } = await Swal.fire({
        position: "center",
        title: "ท่านยืนยันที่จะปิดคลินิกใช่หรือไม่",
        showDenyButton: true,
        confirmButtonText: "ตกลง",
        denyButtonText: `ยกเลิก`,
        customClass: {
          container: "swal2-container",
        },
      });
      if (isConfirmed) {
        await doctorDayoffSchedule(token, event._id);
        Swal.fire("Saved!", "", "success");
      }
    } catch (error) {
      Swal.fire("Saved!", "", "error");
    }
  };

  const onFetchScheduleById = async (id: string) => {
    try {
      const resp = await fetchScheduleById(token, id);
      setEvent(resp);
      setAppointments(resp.referrals);
      groupFromHospCode(resp.referrals);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (open) {
      onFetchScheduleById(scheduleId);
    }
  }, [scheduleId, open]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => onHandleDrawer(false)}
      BackdropProps={{
        invisible: true,
      }}
      PaperProps={{
        sx: { width: 320 },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pl: 2, pr: 1, py: 2 }}
      >
        <Select
          native
          label="นัดหมาย"
          onChange={(e) => filterHospital(e.target.value)}
        >
          <option value="all">ทั้งหมด</option>
          {hospitals.map((s) => (
            <option key={s} value={s}>
              {s}
            </option>
          ))}
        </Select>
        {isDoctor && !event?.dayOff && (
          <button
            onClick={handleDoctorDayOff}
            className="bg-red-400 rounded-2xl text-white text-lg px-2 py-2"
          >
            ลางาน
          </button>
        )}
      </Stack>

      <Divider />

      <Stack spacing={2} sx={{ px: 2, mt: 2 }}>
        <Select
          native
          label="สถานะ"
          onChange={(e) => filterStatus(e.target.value)}
        >
          <option value="all">ทั้งหมด</option>
          {status.map((s) => (
            <option key={s} value={s}>
              {s}
            </option>
          ))}
        </Select>
      </Stack>

      <Typography
        variant="caption"
        sx={{
          p: 2,
          color: "text.secondary",
          fontWeight: "fontWeightMedium",
        }}
      >
        จำนวนนัดหมาย ({appointments.length})
      </Typography>

      <Box sx={{ height: 1, overflow: "scroll" }}>
        {appointments.map((refer: IReferral) => (
          <ListItemButton
            key={refer._id}
            sx={{
              py: 1.5,
              borderBottom: (theme) => `dashed 1px ${theme.palette.divider} `,
              backgroundColor: colorRefer(refer.status),
            }}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography variant="subtitle2" sx={{ fontSize: 13, mt: 0.5 }}>
                  {refer.data?.ptname}
                </Typography>
              }
              secondary={
                event && (
                  <Typography
                    variant="caption"
                    component="div"
                    sx={{ fontSize: 11, color: "text.disabled" }}
                  >
                    <>{`${fDate(event.start, "DD MMM YY")}`}</>
                  </Typography>
                )
              }
              sx={{ display: "flex", flexDirection: "column-reverse" }}
            />
            {!isDoctor && (
              <>
                <IconButton
                  onClick={(e) => handleMenuOpen(e, refer)}
                  size="large"
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  key={refer.bid}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => {
                    setAnchorEl(null);
                    setSelectedReferral(null);
                  }}
                >
                  <MenuItem onClick={handleOpenAccept}>ยอมรับ</MenuItem>
                  <MenuItem onClick={handleOpenReject}>ปฏิเสธ</MenuItem>
                </Menu>
              </>
            )}
          </ListItemButton>
        ))}
      </Box>
      {selectedReferral && (
        <AppointmentAcceptDialog
          open={acceptDialogOpen}
          cid={selectedReferral.data.cid}
          ptname={selectedReferral.data.ptname}
          hospName={shortHospName(selectedReferral.data.fromHospName)}
          onClose={handleCloseAccept}
          onSubmit={(appointmentInfo: {
            appointmentPoint: string;
            appointmentNotes: string;
          }) => {
            setAcceptDialogOpen(false);
            onAcceptAppointment(
              appointmentInfo.appointmentPoint,
              appointmentInfo.appointmentNotes
            );
          }}
        />
      )}
      {selectedReferral && (
        <AppointmentRejectDialog
          token={token}
          open={rejectDialogOpen}
          cid={selectedReferral.data.cid}
          ptname={selectedReferral.data.ptname}
          hospName={shortHospName(selectedReferral.data.fromHospName)}
          onClose={handleCloseReject}
          onSubmit={(reason: string) => {
            setRejectDialogOpen(false);
            onRejectAppointment(reason);
          }}
        />
      )}
    </Drawer>
  );
}

export default AppointmentDrawer;
