import React, { useEffect, useMemo, useState } from "react";
import ReferNavbar from "./ReferNavbar";
import { IReferral, ITreatmentResult, ReferralType } from "../../types/refers";
import ReferCard from "./ReferCard";
import { Box } from "@mui/material";
import { Ability, AbilityTuple, Subject, MongoQuery } from "@casl/ability";
import { useSelector, useDispatch } from "react-redux";
import { AnyObject } from "@casl/ability/dist/types/types";
import { RootState } from "../../reducers";
import { PermissionEvent, PermissionService } from "../../constants/permission";
import acceptRefer from "../../actions/acceptRefer.action";
import rejectRefer from "../../actions/rejectRefer.action";
import recordTreatmentResult from "../../actions/recordTreatmentResult.action";
import Swal from "sweetalert2";
import { canManageReferral } from "../../functions/refer";

function ReferIn() {
  const dispatch = useDispatch();
  const { appData } = useSelector((state: RootState) => state);
  const { permissionRules, idToken } = appData;

  const [isRefresh, setIsRefresh] = useState(false);
  const [referList, setReferList] = useState<IReferral[]>([]);
  const [ability, setAbility] =
    useState<Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>>();

  const handleAcceptRefer = (bid: string, appointmentInfo: object) => {
    if (!bid) return;

    dispatch(acceptRefer(idToken, bid, appointmentInfo));
    setIsRefresh(true);
  };

  const handleRejectRefer = (bid: string, reason: string) => {
    if (!reason)
      return Swal.fire({
        icon: "error",
        title: "กรุณากรอกข้อมูลเหตุผลการปฏิเสธ",
        confirmButtonText: "ตกลง",
      });

    if (bid) {
      dispatch(rejectRefer(idToken, bid, reason));
      setIsRefresh(true);
    }
  };

  const handleRecordTreatmentResult = (
    bid: string,
    treatmentResult: ITreatmentResult
  ) => {
    if (!bid) return;

    dispatch(recordTreatmentResult(idToken, bid, treatmentResult));
    setIsRefresh(true);
  };

  useEffect(() => {
    if (permissionRules) {
      setAbility(new Ability(permissionRules));
    }
  }, [permissionRules]);

  const canManageRefer = useMemo(() => {
    return canManageReferral(ability);
  }, [ability]);

  return (
    <>
      <ReferNavbar
        referType={ReferralType.REFER_IN}
        onUpdateReferList={(referList) => {
          setIsRefresh(false);
          setReferList(referList);
        }}
        isRefresh={isRefresh}
      />

      {referList?.length > 0 ? (
        <Box
          sx={{
            display: "grid",
            gap: 2,
            p: 2,
            bgcolor: "grey.100",
            gridTemplateColumns: {
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            },
          }}
        >
          {referList.map((refer, index) => (
            <ReferCard
              key={refer.bid}
              avatarText={referList.length - index}
              referral={refer}
              canManageRefer={canManageRefer}
              canEditReferStatus={ability?.can(
                PermissionEvent.UPDATE,
                PermissionService.EDIT_REFER_STATUS
              )}
              onAcceptRefer={(appointmentInfo) => {
                handleAcceptRefer(refer.bid, appointmentInfo);
              }}
              onRejectRefer={(reason) => {
                handleRejectRefer(refer.bid, reason);
              }}
              onRecordTreatmentResult={(treatmentResult) => {
                handleRecordTreatmentResult(refer.bid, treatmentResult);
              }}
            />
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80%",
          }}
        >
          <h3>ไม่พบรีเฟอร์</h3>
        </Box>
      )}
    </>
  );
}

export default ReferIn;
