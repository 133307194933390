import axios from "axios";
import { getApiUrl } from "../functions/Commons";
import { ITreatmentResult } from "../types/refers";

export default (
  token: string,
  bid: string,
  treatmentResult: ITreatmentResult
) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/refer/treatmentResultRecord`;

  const body = {
    ...treatmentResult,
    bid,
  };

  return axios
    .patch(url, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(() => {
      const dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
