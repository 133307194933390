export enum ReferralStatus {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
}

export enum ReferralType {
  REFER_IN = "REFER_IN",
  REFER_OUT = "REFER_OUT",
  REFER_BACK = "REFER_BACK",
}
export interface IReferralStorageAttachment {
  url: string;
  descriptionAttachment: string;
  contentType: string;
  attachmentName: string;
  _id: string;
}

export interface IAppointment {
  _id: string;
  appointmentDateTime: Date;
  appointmentDoctor?: string;
  appointmentNotes?: string;
  appointmentPoint?: string;
  scheduleId: string;
  scheduleDateTime?: Date;
  clinicId: string;
}

export interface IIllnessFile {
  base64: string;
  fileName: string;
  fileType: "LAB" | "EKG" | "X-RAY" | "MRI" | "CTSCAN" | "OTHER";
}

export interface ITreatmentResult {
  importantFormulaResult: string;
  finalDiagnosis: string;
  givenTreatment: string;
  majorDiseaseProgression: string;
  treatmentOutcome?: string;
  dischargePlanning: string;
  illnessFileList?: IIllnessFile[];
  createdAt: Date;
}

export interface IReferral {
  _id: string;
  bid: string;
  visitBid: string;
  patientId: string;
  status: ReferralStatus;
  statusLastUpdated: Date;
  statusLastUpdatedBy: string;
  type: ReferralType;
  rejectReason: string;
  attachments: [
    {
      ipfs_file_hash: string;
      ipfs_file_name: string;
    }
  ];
  storageAttachment: IReferralStorageAttachment[];
  appointments?: IAppointment[];
  data: {
    pttype: string;
    fromHospCode: string;
    fromHospName?: string;
    toHospCode: string;
    toHospName?: string;
    department: string;
    referNumber: string;
    cid: string;
    hn: string;
    referoutId: string;
    an: string;
    doctorName: string;
    visitDateTime: Date;
    referDateTime: Date;
    ptname: string;
    pttypeName: string;
    refername: string;
    referPoint: string;
    preDiagnosis: string;
    icd: string;
    icdName: string;
    spcltyName: string;
    departmentName: string;
    referoutTypeName: string;
    referoutEmergencyTypeName: string;
    treatmentText: string;
    requestText: string;
    otherText: string;
    pmh: string;
    hpi: string;
    dueDateTime: Date;
    expireDateTime: Date;
    reason: string;
    incomplete: boolean;
    treatmentResults?: ITreatmentResult[];
  };
  ptDeceasedBoolean?: boolean;
}
