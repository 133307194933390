import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { Dayjs } from "dayjs";

export default (
  token: string,
  search = "",
  startDate: Dayjs | null = null,
  endDate: Dayjs | null = null,
  actionFilter = "",
  statusFilter = "",
  page = 1,
  limit = 20
) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/mophRefer`;

  return axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        search,
        startDate,
        endDate,
        actionFilter,
        statusFilter,
        page,
        limit,
      },
    })
    .then((res) => {
      const dispatch = {
        mophReferResult: res.data.result,
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
