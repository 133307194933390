import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import acceptRefer from "../../../actions/acceptRefer.action";
import rejectRefer from "../../../actions/rejectRefer.action";
import recordTreatmentResult from "../../../actions/recordTreatmentResult.action";
import {
  ReferAcceptDialog,
  ReferRejectDialog,
  TreatmentResultDialog,
} from "../../Utility/Refers";
import { RootState } from "../../../reducers";
import ReferralStorageAttachDialog from "../../Utility/Refers/ReferAttachDialog/ReferralStorageAttachDialog";
import ReferPreviewAttachDialog from "../../Utility/Refers/ReferAttachDialog/ReferPreviewAttachDialog";
import { IReferral, ITreatmentResult } from "../../../types/refers";
import { PENDING } from "../../../constants/referralStatus";
import ReferHistoryDialog from "../../Utility/Refers/ReferHistoryDialog";

interface IMenuReferral {
  referral: IReferral;
  disableMenu?: boolean;
  onRefresh?(): void;
}

function MenuReferrals({
  referral,
  disableMenu = false,
  onRefresh,
}: IMenuReferral) {
  const dispatch = useDispatch();
  const { appData } = useSelector((state: RootState) => state);
  const { idToken } = appData;
  const { _id, bid, storageAttachment, status } = referral;
  const {
    cid,
    fromHospCode,
    fromHospName = "",
    toHospCode,
    ptname,
  } = referral.data;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [DialogAcceptOpen, setDialogAcceptOpen] = useState(false);
  const [DialogRejectOpen, setDialogRejectOpen] = useState(false);
  const [DialogAttachOpen, setDialogAttachOpen] = useState(false);
  const [DialogPreviewOpen, setDialogPreviewOpen] = useState(false);
  const [DialogHistoryOpen, setDialogHistoryOpen] = useState(false);
  const [DialogTreatmentResultOpen, setDialogTreatmentResultOpen] =
    useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const DialogAcceptOpenFc = () => {
    setDialogAcceptOpen(true);
    handleClose();
  };

  const DialogRejectOpenFc = () => {
    setDialogRejectOpen(true);
    handleClose();
  };

  const DialogAttachOpenFc = () => {
    setDialogAttachOpen(true);
    handleClose();
  };

  const DialogPreviewOpenFc = () => {
    setDialogPreviewOpen(true);
    handleClose();
  };

  const DialogHistoryOpenFc = () => {
    setDialogHistoryOpen(true);
    handleClose();
  };

  const DialogHistoryCloseFc = () => {
    setDialogHistoryOpen(false);
    handleClose();
  };

  const DialogTreatmentResultOpenFc = () => {
    setDialogTreatmentResultOpen(true);
    handleClose();
  };

  const DialogTreatmentResultCloseFc = () => {
    setDialogTreatmentResultOpen(false);
    handleClose();
  };

  const ReferAcceptSubmit = (appointmentInfo: object) => {
    try {
      if (bid) {
        dispatch(acceptRefer(idToken, bid, appointmentInfo));
        onRefresh && onRefresh();
        setDialogAcceptOpen(false);
      }
    } catch (error) {
      alert("การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !");
    }
  };

  const ReferRejectSubmit = (reason: string) => {
    if (!reason) {
      alert("คุณไม่ได้กรอกข้อมูลเหตุผลการปฏิเสธ !");
      return;
    }
    if (bid) {
      try {
        dispatch(rejectRefer(idToken, bid, reason));
        onRefresh && onRefresh();
        setDialogRejectOpen(false);
      } catch (error) {
        alert("การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !");
      }
    }
  };

  const RecordTreatmentResultSubmit = (treatmentResult: ITreatmentResult) => {
    if (!bid) return;

    dispatch(recordTreatmentResult(idToken, bid, treatmentResult));
    onRefresh && onRefresh();
    setDialogTreatmentResultOpen(false);
  };

  const DialogAttachCloseFc = () => {
    setDialogAttachOpen(false);
    onRefresh && onRefresh();
  };

  const DialogPreviewCloseFc = () => {
    setDialogPreviewOpen(false);
    onRefresh && onRefresh();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      {!disableMenu && (
        <>
          {" "}
          <div className="print:hidden">
            <IconButton onClick={handleClick}>
              <MoreVert />
            </IconButton>
          </div>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className="refer-action-menu"
          >
            <div>
              {status.toLocaleUpperCase() === PENDING &&
                toHospCode === appData.loginData.hospCode && (
                  <>
                    <MenuItem onClick={() => DialogAcceptOpenFc()}>
                      ยอมรับ
                    </MenuItem>
                    <MenuItem onClick={() => DialogRejectOpenFc()}>
                      ปฏิเสธ
                    </MenuItem>
                  </>
                )}

              {appData.loginData.hospCode === toHospCode && (
                <MenuItem onClick={() => DialogTreatmentResultOpenFc()}>
                  บันทึกผลการรักษา
                </MenuItem>
              )}

              {!appData?.customerConfig?.disabledFeatures.includes(
                "attachFile"
              ) &&
                appData.loginData.hospCode === fromHospCode && (
                  <MenuItem onClick={() => DialogAttachOpenFc()}>
                    Attachment(แนบไฟล์)
                  </MenuItem>
                )}
              {!appData?.customerConfig?.disabledFeatures.includes(
                "attachFile"
              ) &&
                !appData?.customerConfig?.disabledFeatures.includes(
                  "toHosptailAttach"
                ) &&
                appData.loginData.hospCode === toHospCode && (
                  <MenuItem onClick={() => DialogAttachOpenFc()}>
                    Attachment(แนบไฟล์)
                  </MenuItem>
                )}
              {!appData?.customerConfig?.disabledFeatures.includes(
                "attachFile"
              ) && (
                <MenuItem onClick={() => DialogPreviewOpenFc()}>
                  Preview({storageAttachment?.length || 0})
                </MenuItem>
              )}
              {!appData?.customerConfig?.disabledFeatures.includes(
                "referHistory"
              ) && (
                <MenuItem onClick={() => DialogHistoryOpenFc()}>
                  ประวัติ
                </MenuItem>
              )}
            </div>
          </Menu>
          <ReferAcceptDialog
            open={Boolean(DialogAcceptOpen)}
            cid={cid}
            ptname={ptname}
            hospName={fromHospName}
            onClose={() => setDialogAcceptOpen(false)}
            onSubmit={(appointmentInfo) => ReferAcceptSubmit(appointmentInfo)}
          />
          <ReferRejectDialog
            open={Boolean(DialogRejectOpen)}
            cid={cid}
            ptname={ptname}
            hospName={fromHospName}
            onClose={() => setDialogRejectOpen(false)}
            onSubmit={(reason) => ReferRejectSubmit(reason)}
          />
          <ReferralStorageAttachDialog
            open={Boolean(DialogAttachOpen)}
            onClose={() => DialogAttachCloseFc()}
            referDocId={_id}
          />
          <ReferPreviewAttachDialog
            open={Boolean(DialogPreviewOpen)}
            onClose={() => DialogPreviewCloseFc()}
            storageAttachment={storageAttachment}
            fromHospCode={fromHospCode}
            userHospCode={appData.loginData.hospCode}
          />
          <ReferHistoryDialog
            referral={referral}
            open={Boolean(DialogHistoryOpen)}
            onClose={() => DialogHistoryCloseFc()}
          />
          <TreatmentResultDialog
            open={Boolean(DialogTreatmentResultOpen)}
            cid={referral.data.cid}
            ptname={referral.data.ptname}
            hospName={fromHospName}
            onClose={() => DialogTreatmentResultCloseFc()}
            onSubmit={(treatmentResult) => {
              RecordTreatmentResultSubmit(treatmentResult);
            }}
          />
        </>
      )}
    </div>
  );
}

export default MenuReferrals;
