import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import { ThaiDateTime } from "../../functions/FuncDateTimes";
import Tooltip from "@mui/material/Tooltip";
import fetchMophReferLogs from "../../actions/MophRefer/fetchMophReferLogs.action";
import { IMophReferLog } from "../../types/mophRefer";
import ReplayIcon from "@mui/icons-material/Replay";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { MophReferAction } from "../../constants/mophRefer";
import retryReferData from "../../api/MophRefer/retryReferData.service";
import fetchMophReferData from "../../api/MophRefer/fetchMophReferData.service";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DatePicker from "@mui/lab/DatePicker";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ReactJson from "react-json-view";

const MophRefer = () => {
  const dispatch = useDispatch();
  const { appData } = useSelector((state: RootState) => state);
  const {
    idToken,
    mophReferResult,
  }: {
    idToken: string;
    mophReferResult: {
      docs: IMophReferLog[];
      totalDocs: number;
      page: number;
      limit: number;
    };
  } = appData;

  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [actionFilter, setStepFilter] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [selectedData, setSelectedData] = useState<object | null>(null);

  const handleRetryReferData = async (data: IMophReferLog) => {
    try {
      setLoading((prev) => ({ ...prev, [data.referralBid]: true }));

      const response = await retryReferData(
        idToken,
        data.action,
        data.referralBid,
        data.visitBid
      );

      Swal.fire({
        title: "สำเร็จ",
        text: response.message_th || response.message || "ส่งข้อมูลสำเร็จ",
        icon: "success",
        confirmButtonText: "ตกลง",
        didOpen: () => {
          const swalContainer = document.querySelector(
            ".swal2-container"
          ) as HTMLElement;
          if (swalContainer) {
            swalContainer.style.zIndex = "2000";
          }
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(
            fetchMophReferLogs(
              idToken,
              search,
              startDate,
              endDate,
              actionFilter,
              statusFilter,
              page + 1,
              rowsPerPage
            )
          );
        }
      });
    } catch (error: any) {
      Swal.fire({
        title: "ไม่สำเร็จ",
        text: error.message,
        icon: "error",
        confirmButtonText: "ตกลง",
        didOpen: () => {
          const swalContainer = document.querySelector(
            ".swal2-container"
          ) as HTMLElement;
          if (swalContainer) {
            swalContainer.style.zIndex = "2000";
          }
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(
            fetchMophReferLogs(
              idToken,
              search,
              startDate,
              endDate,
              actionFilter,
              statusFilter,
              page + 1,
              rowsPerPage
            )
          );
        }
      });
    } finally {
      setLoading((prev) => ({ ...prev, [data.referralBid]: false }));
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleRowClick = async (data: IMophReferLog) => {
    if (data.isSuccessful) {
      try {
        const response = await fetchMophReferData(idToken, data.referralBid);

        if (response.status !== 200 || !response.result) {
          throw new Error("Refer data not found");
        }

        setSelectedData(response.result);
      } catch (error: any) {
        Swal.fire({
          title: "ไม่สำเร็จ",
          text: error.message,
          icon: "error",
          confirmButtonText: "ตกลง",
          didOpen: () => {
            const swalContainer = document.querySelector(
              ".swal2-container"
            ) as HTMLElement;
            if (swalContainer) {
              swalContainer.style.zIndex = "2000";
            }
          },
        });
      }
    }
  };

  useEffect(() => {
    dispatch(
      fetchMophReferLogs(
        idToken,
        search,
        startDate,
        endDate,
        actionFilter,
        statusFilter,
        page + 1,
        rowsPerPage
      )
    );
  }, [
    dispatch,
    idToken,
    search,
    startDate,
    endDate,
    actionFilter,
    statusFilter,
    page,
    rowsPerPage,
  ]);

  return (
    <Box sx={{ padding: 3, marginTop: 8 }}>
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={12} sm={6} md={3}>
          <DatePicker
            inputFormat="DD/MM/YYYY"
            label="เริ่มวันที่"
            minDate={dayjs().subtract(90, "day")}
            maxDate={dayjs()}
            value={startDate}
            onChange={(date) => {
              setStartDate(date);
              setPage(0);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <DatePicker
            inputFormat="DD/MM/YYYY"
            label="ถึงวันที่"
            minDate={dayjs().subtract(90, "day")}
            maxDate={dayjs()}
            value={endDate}
            onChange={(date) => {
              setEndDate(date);
              setPage(0);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Select
            size="small"
            value={actionFilter}
            onChange={(e) => {
              setStepFilter(e.target.value);
              setPage(0);
            }}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">🔄 ขั้นตอน</MenuItem>
            <MenuItem value={MophReferAction.ADD_REFER_DATA}>
              📤 ส่งข้อมูล
            </MenuItem>
            <MenuItem value={MophReferAction.UPDATE_REFER_DATA}>
              🗒️ อัพเดทข้อมูล
            </MenuItem>
            <MenuItem value={MophReferAction.TREATMENT_RESULT_RECORD}>
              ✏️ บันทึกผลการรักษา
            </MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Select
            size="small"
            value={statusFilter}
            onChange={(e) => {
              setStatusFilter(e.target.value);
              setPage(0);
            }}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">📊 สถานะ</MenuItem>
            <MenuItem value="true">✅ สำเร็จ</MenuItem>
            <MenuItem value="false">❌ ไม่สำเร็จ</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} md={11}>
          <TextField
            size="small"
            label="🔍 ค้นหาโดยเลขบัตรประชาชน เลขรีเฟอร์ หรือ ชื่อผู้ป่วย"
            variant="outlined"
            fullWidth
            value={search}
            onChange={handleSearchChange}
          />
        </Grid>

        <Grid item xs={12} md={1}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => {
              setSearch("");
              setStartDate(null);
              setEndDate(null);
              setStepFilter("");
              setStatusFilter("");
              setPage(0);
            }}
          >
            ล้าง
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table aria-label="Moph Refer List">
          <TableHead>
            <TableRow>
              <TableCell width={160}>วันที่</TableCell>
              <TableCell>เลขรีเฟอร์</TableCell>
              <TableCell>เลขบัตรประชาชน</TableCell>
              <TableCell>ชื่อผู้ป่วย</TableCell>
              <TableCell>หมายเหตุ</TableCell>
              <TableCell align="center" width={120}>
                ขั้นตอน
              </TableCell>
              <TableCell align="center" width={120}>
                สถานะ
              </TableCell>
              <TableCell align="center" width={120}>
                สถานะส่งซ้ำ
              </TableCell>
              <TableCell width={80} />
            </TableRow>
          </TableHead>
          <TableBody>
            {mophReferResult.docs?.length > 0 ? (
              mophReferResult.docs.map((item, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(item)}
                  sx={{
                    height: 63,
                    cursor: item.isSuccessful ? "pointer" : "default",
                    ...(item.isSuccessful && {
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }),
                  }}
                >
                  <TableCell>{ThaiDateTime(item.createdAt)}</TableCell>
                  <TableCell>{item.referNumber}</TableCell>
                  <TableCell>{item.cid}</TableCell>
                  <TableCell>{item.patientName}</TableCell>
                  <TableCell>
                    {item.response.status !== 200 && item.response.message_th && (
                      <Tooltip title={item.response.message_th}>
                        <span>
                          {item.response.message_th.length > 30
                            ? `${item.response.message_th.slice(0, 30)}...`
                            : item.response.message_th}
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      sx={{ width: "100%" }}
                      label={
                        item.action === MophReferAction.ADD_REFER_DATA
                          ? "ส่งข้อมูล"
                          : item.action === MophReferAction.UPDATE_REFER_DATA
                          ? "อัพเดทข้อมูล"
                          : "บันทึกผลการรักษา"
                      }
                      color={
                        item.action === MophReferAction.ADD_REFER_DATA
                          ? "primary"
                          : item.action === MophReferAction.UPDATE_REFER_DATA
                          ? "warning"
                          : "secondary"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      sx={{ width: "100%" }}
                      label={item.isSuccessful ? "สำเร็จ" : "ไม่สำเร็จ"}
                      color={item.isSuccessful ? "success" : "error"}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      sx={{ width: "100%" }}
                      label={
                        item.isSuccessful
                          ? "สำเร็จ"
                          : item.isRetried
                          ? "ส่งแล้ว"
                          : "ยังไม่ส่ง"
                      }
                      color={
                        item.isSuccessful
                          ? "success"
                          : item.isRetried
                          ? "error"
                          : "default"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {!item.isSuccessful && (
                      <Tooltip title="ส่งซ้ำ">
                        <IconButton
                          size="small"
                          onClick={() => handleRetryReferData(item)}
                          disabled={loading[item.referralBid] || false}
                          className={
                            loading[item.referralBid] ? "animate-spin" : ""
                          }
                        >
                          <ReplayIcon
                            fontSize="small"
                            color="primary"
                            sx={{ transform: "scaleX(-1)" }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  ไม่มีรายการ
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <Dialog
          open={Boolean(selectedData)}
          onClose={() => setSelectedData(null)}
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            {selectedData && (
              <ReactJson
                src={selectedData}
                enableClipboard={(copy) =>
                  navigator.clipboard.writeText(
                    typeof copy.src === "string"
                      ? copy.src
                      : JSON.stringify(copy.src, null, 2)
                  )
                }
                displayDataTypes={false}
                name={false}
              />
            )}
          </DialogContent>
        </Dialog>
      </TableContainer>

      <TablePagination
        component="div"
        count={mophReferResult?.totalDocs || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) =>
          setRowsPerPage(parseInt(e.target.value, 10))
        }
      />
    </Box>
  );
};

export default MophRefer;
