import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { IAppointment } from "../../../types/refers";
import { ThaiDateTime } from "../../../functions/FuncDateTimes";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ExpandMore } from "@mui/icons-material";
import AccordionDetails from "@mui/material/AccordionDetails";
import Tooltip from "@mui/material/Tooltip";
import { truncateText } from "../../../functions/Commons";

interface AppointmentsListProps {
  appointments?: IAppointment[];
}

const AppointmentDetail: React.FC<AppointmentsListProps> = ({
  appointments = [],
}) => {
  return (
    <>
      {appointments && appointments.length > 0 && (
        <Box>
          <Divider sx={{ my: 2 }}>นัดหมาย</Divider>

          {appointments.map((appointment, index) => (
            <Accordion key={index} sx={{ my: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Typography>
                  นัดหมาย #{index + 1} (
                  {ThaiDateTime(appointment.appointmentDateTime)})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Tooltip title={appointment.appointmentPoint || ""}>
                  <Box>
                    <Typography component={"span"} fontWeight="bold">
                      จุดนัดหมาย :
                    </Typography>
                    <Typography component={"span"}>
                      {" "}
                      {truncateText(appointment.appointmentPoint || "-", 200)}
                    </Typography>
                  </Box>
                </Tooltip>

                <Tooltip title={appointment.appointmentNotes || ""}>
                  <Box>
                    <Typography component={"span"} fontWeight="bold">
                      หมายเหตุ :
                    </Typography>
                    <Typography component={"span"}>
                      {" "}
                      {truncateText(appointment.appointmentNotes || "-", 200)}
                    </Typography>
                  </Box>
                </Tooltip>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </>
  );
};

export default AppointmentDetail;
