import React from "react";
import { useForm, Controller } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import IconButton from "@mui/material/IconButton";
import { ITreatmentResult } from "../../../../types/refers";
import { Grid } from "@mui/material";

interface ITreatmentResultRecordDialog {
  open: boolean;
  cid: string;
  ptname: string;
  hospName: string;
  onClose(): void;
  onSubmit(treatmentResult: ITreatmentResult): void;
}

const TreatmentResultDialog = ({
  open,
  cid,
  ptname,
  hospName,
  onClose,
  onSubmit,
}: ITreatmentResultRecordDialog) => {
  const { handleSubmit, control } = useForm();

  const submit = (treatmentResult: ITreatmentResult) => {
    onSubmit(treatmentResult);
  };

  return (
    <Dialog
      fullWidth
      sx={{ "& .MuiDialog-paper": { borderRadius: "16px" } }}
      maxWidth="lg"
      open={open}
      onClose={() => onClose()}
    >
      <form onSubmit={handleSubmit((e: ITreatmentResult) => submit(e))}>
        <DialogTitle>
          <NoteAltIcon
            sx={{
              p: "4px",
              borderRadius: "50%",
              mr: "8px",
              width: "32px",
              height: "32px",
              backgroundColor: "#ffe676",
              color: "#ff8c00",
            }}
          />
          บันทึกผลการรักษา
          <IconButton
            className="float-right"
            onClick={() => onClose()}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {cid && <DialogContentText>CID : {cid}</DialogContentText>}
          {ptname && <DialogContentText>ผู้ป่วย : {ptname}</DialogContentText>}
          {hospName && <DialogContentText>จาก : {hospName}</DialogContentText>}

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    required
                    multiline
                    margin="dense"
                    label="ผลการชันสูตรที่สำคัญ"
                    variant="outlined"
                    size="small"
                    rows={3}
                  />
                }
                control={control}
                name="importantFormulaResult"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    required
                    multiline
                    margin="dense"
                    label="การวินิจฉัยโรคขั้นสุดท้าย"
                    variant="outlined"
                    size="small"
                    rows={3}
                  />
                }
                control={control}
                name="finalDiagnosis"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    required
                    multiline
                    margin="dense"
                    label="การรักษาที่ได้ให้ไว้"
                    variant="outlined"
                    size="small"
                    rows={3}
                  />
                }
                control={control}
                name="givenTreatment"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    required
                    multiline
                    margin="dense"
                    label="การดำเนินโรคที่สำคัญ"
                    variant="outlined"
                    size="small"
                    rows={3}
                  />
                }
                control={control}
                name="majorDiseaseProgression"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    required
                    multiline
                    margin="dense"
                    label="แผนดูแลผู้ป่วยหลังส่งตัวกลับสถานพยาบาลต้นทาง"
                    variant="outlined"
                    size="small"
                    rows={3}
                  />
                }
                control={control}
                name="dischargePlanning"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    multiline
                    margin="dense"
                    label="ผลการรักษา"
                    variant="outlined"
                    size="small"
                    rows={3}
                  />
                }
                control={control}
                name="treatmentOutcome"
              />
            </Grid>
          </Grid>

          <Button
            fullWidth
            type="submit"
            size="small"
            className="!bg-sky-500 !text-white !mt-3
              hover:!bg-sky-600"
          >
            บันทึกผล
          </Button>

          <Button
            fullWidth
            size="small"
            className="!mt-3 !text-sky-600
            hover:!bg-transparent"
            onClick={() => onClose()}
          >
            ยกเลิก
          </Button>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default TreatmentResultDialog;
