import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WarningIcon from "@mui/icons-material/Warning";
import { getReferColor } from "../../functions/refer";
import { shortHospName, StrEncrypt } from "../../functions/FuncPerjer";
import { ThaiDateTime } from "../../functions/FuncDateTimes";
import {
  ReferAcceptDialog,
  ReferRejectDialog,
  TreatmentResultDialog,
} from "../Utility/Refers";
import EmergencyBadge from "./EmergencyBadge";
import {
  ReferralStatus,
  IReferral,
  ITreatmentResult,
} from "../../types/refers";
import { RootState } from "../../reducers";
import "../../styles/refers/ReferCard.scss";
import ReferralStorageAttachDialog from "../Utility/Refers/ReferAttachDialog/ReferralStorageAttachDialog";
import { REFER_BACK } from "../../constants/referType";
import { COMPLETE } from "../../constants/referralStatus";
import readRefer from "../../actions/Refers/readRefer.action";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import AppointmentListDialog from "../AppointmentOPD/components/AppointmentListDialog";

interface IReferCard {
  referral: IReferral;
  avatarText?: number | string;
  canManageRefer?: boolean;
  canEditReferStatus?: boolean;
  onAcceptRefer?(appointmentInfo: object): void;
  onRejectRefer?(reason: string): void;
  onCancelAppointment?(): void;
  onRecordTreatmentResult?(treatmentResult: ITreatmentResult): void;
}

export default function ReferCard({
  referral,
  avatarText,
  onAcceptRefer,
  onRejectRefer,
  onCancelAppointment,
  canManageRefer = false,
  canEditReferStatus = false,
  onRecordTreatmentResult,
}: IReferCard) {
  const { t } = useTranslation("appointment");

  const dispatch = useDispatch();
  const history = useHistory();

  const { appProperty, appData } = useSelector((state: RootState) => state);
  const { idToken } = appData;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [acceptDialogOpen, setAcceptDialogOpen] = useState<boolean>(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState<boolean>(false);
  const [attachDialogOpen, setAttachDialogOpen] = useState<boolean>(false);
  const [appointmentListDialogOpen, setAppointmentListDialogOpen] =
    useState<boolean>(false);
  const [treatmentResultDialogOpen, setTreatmentResultDialogOpen] =
    useState<boolean>(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAcceptDialogOpen = () => {
    setAnchorEl(null);
    setAcceptDialogOpen(true);
  };

  const handleRejectDialogOpen = () => {
    setAnchorEl(null);
    setRejectDialogOpen(true);
  };

  const handleTreatmentResultDialogOpen = () => {
    setAnchorEl(null);
    setTreatmentResultDialogOpen(true);
  };

  const DialogAttachCloseFc = (status: boolean) => {
    setAttachDialogOpen(status);
    handleMenuClose();
  };

  const viewPatientInfo = () => {
    const { bid, data, visitBid } = referral;
    const { cid, hn, fromHospCode: hospCode } = data;

    dispatch(readRefer(idToken, bid, data.referDateTime));

    const cidEncrypted2 = StrEncrypt(cid, appProperty.ScKey);
    if (cidEncrypted2) {
      history.push({
        pathname: `/patient-emr/${cidEncrypted2}/${visitBid}/${hospCode}/${hn}`,
      });
    } else {
      history.push({
        pathname: `/patient-emr/unknow/${visitBid}/${hospCode}/${hn}`,
      });
    }
  };

  return (
    <Card
      id="refer-card"
      variant="outlined"
      className={`${getReferColor(
        referral
      )} !rounded-xl !text-gray-500 cursor-pointer max-h-72
    hover:bg-gray-50`}
    >
      <CardHeader
        avatar={
          <Avatar title={`Type: ${referral.data.referoutEmergencyTypeName}`}>
            {avatarText}
          </Avatar>
        }
        action={
          <Box className="flex">
            <EmergencyBadge
              emergencyTypeName={
                referral.ptDeceasedBoolean
                  ? "Unconcious"
                  : referral.data.referoutEmergencyTypeName
              }
            />
            <Box>
              {(canManageRefer || canEditReferStatus) && (
                <Box>
                  <IconButton
                    onClick={(event) => handleMenuOpen(event)}
                    size="large"
                  >
                    <MoreVertIcon />
                  </IconButton>

                  <Menu
                    key={referral.bid}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => handleMenuClose()}
                  >
                    {((canManageRefer &&
                      [ReferralStatus.PENDING].includes(referral.status)) ||
                      canEditReferStatus) && (
                      <>
                        <MenuItem onClick={() => handleAcceptDialogOpen()}>
                          ยอมรับ
                        </MenuItem>
                        <MenuItem onClick={() => handleRejectDialogOpen()}>
                          ปฏิเสธ
                        </MenuItem>
                      </>
                    )}

                    <MenuItem onClick={() => handleTreatmentResultDialogOpen()}>
                      บันทึกผลการรักษา
                    </MenuItem>
                    {!appData?.customerConfig?.disabledFeatures.includes(
                      "attachFile"
                    ) &&
                      appData.loginData.hospCode ===
                        referral.data.fromHospCode && (
                        <MenuItem onClick={() => DialogAttachCloseFc(true)}>
                          แนบไฟล์
                        </MenuItem>
                      )}

                    {!appData?.customerConfig?.disabledFeatures.includes(
                      "attachFile"
                    ) &&
                      !appData?.customerConfig?.disabledFeatures.includes(
                        "toHosptailAttach"
                      ) &&
                      appData.loginData.hospCode ===
                        referral.data.toHospCode && (
                        <MenuItem onClick={() => DialogAttachCloseFc(true)}>
                          แนบไฟล์
                        </MenuItem>
                      )}
                  </Menu>

                  <ReferAcceptDialog
                    open={acceptDialogOpen}
                    cid={referral.data.cid}
                    ptname={referral.data.ptname}
                    hospName={shortHospName(referral.data.fromHospName)}
                    onClose={() => setAcceptDialogOpen(false)}
                    onSubmit={(appointmentInfo) => {
                      setAcceptDialogOpen(false);
                      onAcceptRefer && onAcceptRefer(appointmentInfo);
                    }}
                  />

                  <ReferRejectDialog
                    open={rejectDialogOpen}
                    cid={referral.data.cid}
                    ptname={referral.data.ptname}
                    hospName={shortHospName(referral.data.fromHospName)}
                    onClose={() => setRejectDialogOpen(false)}
                    onSubmit={(reason) => {
                      setRejectDialogOpen(false);
                      onRejectRefer && onRejectRefer(reason);
                    }}
                  />

                  <ReferralStorageAttachDialog
                    open={Boolean(attachDialogOpen)}
                    onClose={() => DialogAttachCloseFc(false)}
                    referDocId={referral._id}
                  />

                  <TreatmentResultDialog
                    open={treatmentResultDialogOpen}
                    cid={referral.data.cid}
                    ptname={referral.data.ptname}
                    hospName={shortHospName(referral.data.fromHospName)}
                    onClose={() => setTreatmentResultDialogOpen(false)}
                    onSubmit={(treatmentResult) => {
                      setTreatmentResultDialogOpen(false);
                      onRecordTreatmentResult &&
                        onRecordTreatmentResult(treatmentResult);
                    }}
                  />
                </Box>
              )}

              {referral.data.fromHospCode === appData?.loginData?.hospCode && (
                <Box>
                  <IconButton
                    onClick={(event) => handleMenuOpen(event)}
                    size="large"
                  >
                    <MoreVertIcon />
                  </IconButton>

                  <Menu
                    key={referral.bid}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => handleMenuClose()}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(`/slot-schedule/${referral._id}`)
                      }
                    >
                      ทำนัด
                    </MenuItem>

                    {referral.status === COMPLETE && (
                      <MenuItem
                        onClick={() => setAppointmentListDialogOpen(true)}
                      >
                        {t("appointmentList")}
                      </MenuItem>
                    )}
                  </Menu>

                  {referral.appointments && (
                    <AppointmentListDialog
                      referral={referral}
                      open={appointmentListDialogOpen}
                      onClose={() => setAppointmentListDialogOpen(false)}
                      onCancelAppointment={() => {
                        onCancelAppointment && onCancelAppointment();
                      }}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        }
        title={`From : ${
          shortHospName(referral.data.fromHospName) ||
          referral.data.fromHospCode
        }`}
        subheader={`To : ${
          shortHospName(referral.data.toHospName) || referral.data.toHospCode
        }`}
      />

      <CardContent onClick={() => viewPatientInfo()}>
        <div>
          <div
            className={`text-2xl 
          ${referral.ptDeceasedBoolean ? "text-gray-500" : "text-sky-500"}`}
          >
            {referral.data.incomplete && (
              <Tooltip title="Missing Patient Info">
                <WarningIcon className="text-amber-500 mr-2" />
              </Tooltip>
            )}
            {referral.data.ptname}
          </div>
          <div>
            <Typography>CID: {referral.data.cid}</Typography>
          </div>

          <div className="flex">
            <Typography className="truncate text-sm self-center">
              {referral.type === REFER_BACK ? "Diagnosis: " : "Pre Diag: "}
              {referral.data.preDiagnosis}
            </Typography>
          </div>

          <div>
            <Typography className="truncate">
              ICD: {`${referral.data.icd} ${referral.data.icdName}`}
            </Typography>
          </div>

          <div>
            <Typography className="flex">
              Refer Point:
              <span className="text-green-500 ml-1">
                {referral.data.referPoint}
              </span>
            </Typography>
          </div>

          <div>
            <Typography
              className={`text-sm
            ${referral.ptDeceasedBoolean ? "text-white" : "text-gray-400"}`}
            >
              Refer Date: {ThaiDateTime(referral.data.referDateTime)}
            </Typography>
          </div>
          {referral.appointments && referral.appointments.length > 0 && (
            <Tooltip
              title={
                <Typography variant="caption">
                  {referral.appointments?.map((a, index) => (
                    <React.Fragment key={index}>
                      {`${index + 1}. ${ThaiDateTime(a.appointmentDateTime)} ${
                        a.appointmentPoint
                      }`}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
              }
            >
              <Typography className="text-gray-400 truncate" noWrap>
                Appointment Date:{" "}
                {referral.appointments
                  ?.map((a) => ThaiDateTime(a.appointmentDateTime))
                  .join(", ")
                  .slice(0, 40)}
                {referral.appointments?.length > 1 && "..."}
              </Typography>
            </Tooltip>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
