import {
  Analytics,
  CreditCard,
  ExitToApp,
  LocalHospital,
  Settings,
  SupervisedUserCircle,
  School,
  Badge,
} from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import { PermissionService } from "../../constants/permission";

export interface IMenuItem {
  text: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  link: string;
  pathImage: string;
  description: string;
  group: string;
  permission?: string[];
}

const referIn: IMenuItem = {
  pathImage: "/refer_in.png",
  text: "sidebar.referIn",
  description: "description.referIn",
  link: "/refer-in",
  icon: ExitToApp,
  group: "referral",
  permission: [
    PermissionService.REFERRAL.ANY_HOSPITAL_REFERRAL,
    PermissionService.REFERRAL.OWN_HOSPITAL_REFERRAL,
    PermissionService.REFERRAL.CHILD_HOSPITAL_REFERRAL,
  ],
};

const referOut: IMenuItem = {
  pathImage: "/refer_out.png",
  text: "sidebar.referOut",
  description: "description.referOut",
  link: "/refer-out",
  icon: ExitToApp,
  group: "referral",
  permission: [
    PermissionService.REFERRAL.ANY_HOSPITAL_REFERRAL,
    PermissionService.REFERRAL.OWN_HOSPITAL_REFERRAL,
    PermissionService.REFERRAL.CHILD_HOSPITAL_REFERRAL,
  ],
};

const knowledge: IMenuItem = {
  pathImage: "/knowledge.png",
  text: "sidebar.knowledge",
  description: "description.knowledge",
  link: "/",
  icon: School,
  group: "none",
  permission: [PermissionService.KNOWLEDGE_GRAPH],
};

const onlineHospital: IMenuItem = {
  pathImage: "/online_hospital.png",
  text: "sidebar.onlineHospital",
  description: "description.onlineHospital",
  link: "/online-hospital",
  icon: LocalHospital,
  group: "setting",
  permission: [PermissionService.ONLINE_STATUS],
};

const report: IMenuItem = {
  pathImage: "/report.png",
  text: "sidebar.reportHospital",
  description: "description.report",
  link: "/hosp-refer-report",
  icon: Analytics,
  group: "referral",
  permission: [
    PermissionService.REPORT.ANY_HOSPITAL_REPORT,
    PermissionService.REPORT.OWN_HOSPITAL_REPORT,
    PermissionService.REPORT.CHILD_HOSPITAL_REPORT,
  ],
};

const billingProvider: IMenuItem = {
  pathImage: "/billing.png",
  text: "sidebar.provider",
  description: "description.provider",
  link: "/billing-charge/to",
  icon: CreditCard,
  group: "billing",
};

const billingPayer: IMenuItem = {
  pathImage: "/billing.png",
  text: "sidebar.payer",
  description: "description.payer",
  link: "/billing-charge/from",
  icon: CreditCard,
  group: "billing",
};

const settings: IMenuItem = {
  text: "sidebar.setting",
  icon: Settings,
  link: "/settings",
  description: "description.setting",
  pathImage: "/setting_billing.png",
  group: "billing",
};

const userManagement: IMenuItem = {
  text: "sidebar.userManage",
  icon: SupervisedUserCircle,
  link: "/user-management",
  description: "description.userManage",
  pathImage: "/user_management.png",
  group: "setting",
  permission: [
    PermissionService.MANAGEMENT.USER_MANAGEMENT.ANY_HOSPITAL_USER,
    PermissionService.MANAGEMENT.USER_MANAGEMENT.OWN_HOSPITAL_USER,
    PermissionService.MANAGEMENT.USER_MANAGEMENT.CHILD_HOSPITAL_USER,
  ],
};

const permissionManagement: IMenuItem = {
  text: "sidebar.permissionManage",
  icon: Badge,
  link: "/permission-management",
  description: "description.permissionManage",
  pathImage: "/user_management.png",
  group: "setting",
  permission: [PermissionService.MANAGEMENT.PERMISSION_MANAGEMENT],
};
const populateHealth: IMenuItem = {
  text: "sidebar.populateManage",
  icon: SupervisedUserCircle,
  link: "/report-link",
  description: "description.populateManage",
  pathImage: "/populate_health.png",
  group: "none",
  permission: [PermissionService.POPULATION_HEALTH],
};
const sampleDataManagement: IMenuItem = {
  text: "Sample Data Management",
  icon: Badge,
  link: "/manage/sample/data",
  description: "จัดการข้อมูลตัวอย่าง",
  pathImage: "/user_management.png",
  group: "setting",
  permission: [PermissionService.SAMPLE_DATA],
};
const reasonReferManagement: IMenuItem = {
  text: "Reason Refer Management",
  icon: Badge,
  link: "/management/reason",
  description: "จัดการตัวเลือกการส่งต่อผู้ป่วย",
  pathImage: "/setting_billing.png",
  group: "setting",
  permission: [PermissionService.SETTING_REASON_REFERRAL],
};

const departmentManagement: IMenuItem = {
  text: "Depart Management",
  icon: Badge,
  link: "/setting-department",
  description: "จัดการแผนก",
  pathImage: "/setting_billing.png",
  group: "none",
  permission: [PermissionService.APPOINTMENT_OPD],
};

const scheduleManagement: IMenuItem = {
  text: "Schedule Management",
  icon: Badge,
  link: "/setting-schedule",
  description: "จัดการตารางแพทย์",
  pathImage: "/setting_billing.png",
  group: "none",
  permission: [PermissionService.APPOINTMENT_OPD],
};

const verifyAppointment: IMenuItem = {
  text: "Verify Appointment",
  icon: Badge,
  link: "/verify-appointment",
  description: "ตอบรับนัดหมาย",
  pathImage: "/setting_billing.png",
  group: "none",
  permission: [PermissionService.APPOINTMENT_OPD],
};

const loginHistory: IMenuItem = {
  text: "Login History",
  icon: Badge,
  link: "/login-history",
  description: "ประวัติการเข้าใช้งาน",
  pathImage: "/login_history.png",
  group: "none",
  permission: [
    PermissionService.LOGIN_HISTORY.ANY_HOSPITAL_LOGIN_HISTORY,
    PermissionService.LOGIN_HISTORY.CHILD_HOSPITAL_LOGIN_HISTORY,
    PermissionService.LOGIN_HISTORY.OWN_HOSPITAL_LOGIN_HISTORY,
  ],
};

const mophRefer: IMenuItem = {
  text: "MOPH Refer",
  icon: Badge,
  link: "/moph-refer",
  description: "",
  pathImage: "/moph_refer.png",
  group: "none",
  permission: [PermissionService.MOPH_REFER],
};

export const menu: IMenuItem[] = [
  referIn,
  referOut,
  knowledge,
  onlineHospital,
  report,
  billingProvider,
  billingPayer,
  settings,
  userManagement,
  permissionManagement,
  populateHealth,
  sampleDataManagement,
  reasonReferManagement,
  departmentManagement,
  scheduleManagement,
  verifyAppointment,
  loginHistory,
  mophRefer,
];
