import React from "react";
import { Box, Typography } from "@mui/material";
import { ThaiDateTime } from "../../functions/FuncDateTimes";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ExpandMore } from "@mui/icons-material";
import AccordionDetails from "@mui/material/AccordionDetails";
import Tooltip from "@mui/material/Tooltip";
import { ITreatmentResult } from "../../types/refers";
import { truncateText } from "../../functions/Commons";
import Divider from "@mui/material/Divider";

interface TreatmentResultsListProps {
  treatmentResults?: ITreatmentResult[];
}

const TreatmentResultDetail: React.FC<TreatmentResultsListProps> = ({
  treatmentResults = [],
}) => {
  return (
    <>
      {treatmentResults && treatmentResults.length > 0 && (
        <Box>
          <Divider sx={{ my: 2 }}>ผลการรักษา</Divider>

          {treatmentResults.map((treatmentResult, index) => (
            <Accordion key={index} sx={{ my: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Typography>
                  ผลการรักษา #{index + 1} (
                  {ThaiDateTime(treatmentResult.createdAt)})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Tooltip title={treatmentResult.importantFormulaResult}>
                  <Box>
                    <Typography component={"span"} fontWeight="bold">
                      ผลการชันสูตรที่สำคัญ :
                    </Typography>
                    <Typography component={"span"}>
                      {" "}
                      {truncateText(
                        treatmentResult.importantFormulaResult,
                        200
                      )}
                    </Typography>
                  </Box>
                </Tooltip>

                <Tooltip title={treatmentResult.finalDiagnosis}>
                  <Box>
                    <Typography component={"span"} fontWeight="bold">
                      การวินิจฉัยโรคขั้นสุดท้าย :
                    </Typography>
                    <Typography component={"span"}>
                      {" "}
                      {truncateText(treatmentResult.finalDiagnosis, 200)}
                    </Typography>
                  </Box>
                </Tooltip>

                <Tooltip title={treatmentResult.givenTreatment}>
                  <Box>
                    <Typography component={"span"} fontWeight="bold">
                      การรักษาที่ได้ให้ไว้ :
                    </Typography>
                    <Typography component={"span"}>
                      {" "}
                      {truncateText(treatmentResult.givenTreatment, 200)}
                    </Typography>
                  </Box>
                </Tooltip>

                <Tooltip title={treatmentResult.majorDiseaseProgression}>
                  <Box>
                    <Typography component={"span"} fontWeight="bold">
                      การดำเนินโรคที่สำคัญ :
                    </Typography>
                    <Typography component={"span"}>
                      {" "}
                      {truncateText(
                        treatmentResult.majorDiseaseProgression || "-",
                        200
                      )}
                    </Typography>
                  </Box>
                </Tooltip>

                <Tooltip title={treatmentResult.dischargePlanning}>
                  <Box>
                    <Typography component={"span"} fontWeight="bold">
                      แผนดูแลผู้ป่วยหลังส่งตัวกลับสถานพยาบาลต้นทาง :
                    </Typography>
                    <Typography component={"span"}>
                      {" "}
                      {truncateText(treatmentResult.dischargePlanning, 200)}
                    </Typography>
                  </Box>
                </Tooltip>

                <Tooltip title={treatmentResult.treatmentOutcome || ""}>
                  <Box>
                    <Typography component={"span"} fontWeight="bold">
                      ผลการรักษา :
                    </Typography>
                    <Typography component={"span"}>
                      {" "}
                      {truncateText(
                        treatmentResult.treatmentOutcome || "-",
                        200
                      )}
                    </Typography>
                  </Box>
                </Tooltip>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </>
  );
};

export default TreatmentResultDetail;
