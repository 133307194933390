import { APPDATA } from "../../constants/appData";
import fetchMophReferLogs from "../../api/MophRefer/fetchMophReferLogs.service";
import { Dayjs } from "dayjs";

export default (
  token: string,
  search: string,
  startDate: Dayjs | null,
  endDate: Dayjs | null,
  actionFilter: string,
  statusFilter: string,
  page: number,
  limit: number
) => ({
  type: APPDATA,
  payload: fetchMophReferLogs(
    token,
    search,
    startDate,
    endDate,
    actionFilter,
    statusFilter,
    page,
    limit
  ),
});
