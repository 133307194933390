import { APPDATA } from "../constants/appData";
import recordTreatmentResult from "../api/recordTreatmentResult.service";
import { ITreatmentResult } from "../types/refers";

export default (
  token: string,
  bid: string,
  treatmentResult: ITreatmentResult
) => ({
  type: APPDATA,
  payload: recordTreatmentResult(token, bid, treatmentResult),
});
