import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IReferral } from "@/types/refers";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import PrintIcon from "@mui/icons-material/Print";
import CancelIcon from "@mui/icons-material/Cancel";
import { printAppointment } from "../../../assets/pdf/printAppointment";
import { ThaiDateTime } from "../../../functions/FuncDateTimes";
import Tooltip from "@mui/material/Tooltip";
import cancelAppointment from "../../../api/AppointmentOPD/cancelAppointment";

interface IAppointmentListDialog {
  referral: IReferral;
  open: boolean;
  onClose(): void;
  onCancelAppointment(): void;
}

const AppointmentListDialog = ({
  referral,
  open,
  onClose,
  onCancelAppointment,
}: IAppointmentListDialog) => {
  const { appData } = useSelector((state: RootState) => state);
  const { idToken } = appData;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => onClose()}
      PaperProps={{ sx: { borderRadius: "10px" } }}
    >
      <Slide
        direction="right"
        in={open}
        timeout={400}
        mountOnEnter
        unmountOnExit
      >
        <DialogContent sx={{ py: 2 }}>
          <Typography variant="h6">รายการนัดหมาย</Typography>

          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table aria-label="Appointment List">
              <TableHead>
                <TableRow>
                  <TableCell>วันที่</TableCell>
                  <TableCell>แพทย์</TableCell>
                  <TableCell>จุดนัดหมาย</TableCell>
                  <TableCell>หมายเหตุ</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {referral.appointments && referral.appointments.length > 0 ? (
                  referral.appointments.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {ThaiDateTime(item.appointmentDateTime)}
                      </TableCell>
                      <TableCell
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.appointmentDoctor ? (
                          item.appointmentDoctor.length > 30 ? (
                            <Tooltip title={item.appointmentDoctor}>
                              <span>{`${item.appointmentDoctor.slice(
                                0,
                                30
                              )}...`}</span>
                            </Tooltip>
                          ) : (
                            item.appointmentDoctor
                          )
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.appointmentPoint ? (
                          item.appointmentPoint.length > 30 ? (
                            <Tooltip title={item.appointmentPoint}>
                              <span>{`${item.appointmentPoint.slice(
                                0,
                                30
                              )}...`}</span>
                            </Tooltip>
                          ) : (
                            item.appointmentPoint
                          )
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.appointmentNotes ? (
                          item.appointmentNotes.length > 30 ? (
                            <Tooltip title={item.appointmentNotes}>
                              <span>{`${item.appointmentNotes.slice(
                                0,
                                30
                              )}...`}</span>
                            </Tooltip>
                          ) : (
                            item.appointmentNotes
                          )
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => printAppointment(referral, item._id)}
                        >
                          <PrintIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            Swal.fire({
                              icon: "warning",
                              title: "ยกเลิกนัดหมาย",
                              text: "ยืนยันการยกเลิกนัดหมาย",
                              showCancelButton: true,
                              confirmButtonText: "ยืนยัน",
                              cancelButtonText: "ยกเลิก",
                              didOpen: () => {
                                const swalContainer = document.querySelector(
                                  ".swal2-container"
                                ) as HTMLElement;
                                if (swalContainer) {
                                  swalContainer.style.zIndex = "2000";
                                }
                              },
                            }).then(async (result) => {
                              if (result.isConfirmed) {
                                await cancelAppointment(
                                  idToken,
                                  referral._id,
                                  item._id,
                                  item.scheduleId
                                );

                                onCancelAppointment();
                              }
                            });
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      ไม่มีนัดหมาย
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Slide>
    </Dialog>
  );
};

export default AppointmentListDialog;
