import React, { useEffect, useState } from "react";
import TDepartment from "../../types/department";
import fetchDepartments from "../../api/SettingAppointment/fetchDepartments";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import Clinic from "./components/Clinic";
import { Button, IconButton } from "@mui/material";
import ModalCreate from "./components/ModalCreate";
import createDepartment from "../../api/SettingAppointment/createDepartment";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import EditDepartmentModal from "./components/EditDepartmentModal";
import Swal from "sweetalert2";
import "../AppointmentOPD/css/AlertStyle.css";

import deleteDepartment from "../../api/SettingAppointment/deleteDepartment";
import updateDepartment from "../../api/SettingAppointment/updateDepartment";
import { TClinic } from "../../types/clinic";
import createClinic from "../../api/SettingAppointment/createClinic";

function DepartmentHospital() {
  const { appData } = useSelector((state: RootState) => state);
  const {
    idToken,
    loginData: { hospCode },
  } = appData;
  const [departments, setDepartments] = useState<TDepartment[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [selectedDepart, setSelectedDepart] = useState<TDepartment | null>(
    null
  );
  const [fetchingClinic, setFetchingClinic] = useState<boolean>(false);

  const onHandleModal = (status: boolean) => setOpen(status);

  const handleModalEdit = (status: boolean) => setOpenEdit(status);

  const onSelectEditDepartment = (department: TDepartment) => {
    setSelectedDepart(department);
    handleModalEdit(true);
  };

  const onFetchDepartments = async (token: string) => {
    try {
      const resp = await fetchDepartments(token);
      setDepartments(resp);
    } catch (error) {
      console.log("fetch department error", error);
    }
  };
  const onCreateDepartment = async (departName: string) => {
    try {
      await createDepartment(idToken, { departName, hospCode });
      await onFetchDepartments(idToken);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "สร้างข้อมูลแผนก",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.log("error create department", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "สร้างข้อมูลแผนกล้มเหลว",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const onEditDepartment = async (
    token: string,
    department: TDepartment,
    departName: string
  ) => {
    try {
      if (!department || !department._id) return;
      await updateDepartment(token, department._id, {
        ...department,
        departName,
      });
      await onFetchDepartments(token);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "แก้ไขข้อมูลแผนกเรียบร้อย",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.log("error create clinic", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "แก้ไขข้อมูลแผนกล้มเหลว",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const onDeleteDepartment = async (department: TDepartment) => {
    try {
      if (!department || !department._id) return;
      const { isConfirmed } = await Swal.fire({
        title: `คุณต้องการที่จะลบแผนก ${department.departName} ใช่หรือไม่`,
        showDenyButton: true,
        confirmButtonText: "บันทึก",
        denyButtonText: `ยกเลิก`,
      });
      if (isConfirmed) {
        await deleteDepartment(idToken, department._id);
        await onFetchDepartments(idToken);
      }
    } catch (error) {
      console.log("delete department error", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "ลบข้อมูลแผนกล้มเหลว",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const onCreateClinic = async (
    clinicName: string,
    department: TDepartment | undefined,
    note: string
  ) => {
    try {
      if (!department) return alert("please select department");
      const data: TClinic = {
        hospCode,
        clinicName,
        note,
        departId: department._id || "",
        doctorId: [],
      };
      await createClinic(idToken, data);
      // set fetching for update component clinic
      setFetchingClinic(!fetchingClinic);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "สร้างข้อมูลคลินิกเรียบร้อย",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.log("error create clinic", error);
    }
  };

  useEffect(() => {
    onFetchDepartments(idToken);
  }, [idToken]);

  return (
    <>
      <div className="mt-20">
        <Button
          sx={{ bgcolor: "white", my: 2, p: 2, borderRadius: 4 }}
          onClick={() => onHandleModal(true)}
          startIcon={<AddCircle />}
        >
          สร้างแผนก / คลินิก
        </Button>
      </div>
      <div className=" bg-white min-h-full max-h-auto">
        {departments &&
          departments.map((department) => (
            <div key={department._id}>
              <div className="flex justify-center">
                <p className="text-center text-xl mx-1 mt-1">
                  {department.departName}
                </p>
                <IconButton onClick={() => onSelectEditDepartment(department)}>
                  <Edit color="warning" fontSize="small" />
                </IconButton>
                <IconButton onClick={() => onDeleteDepartment(department)}>
                  <Delete color="error" fontSize="small" />
                </IconButton>
              </div>

              <Clinic
                token={idToken}
                departId={department._id || ""}
                key={department._id}
                hospCode={hospCode}
                fetching={fetchingClinic}
              />
            </div>
          ))}
        <ModalCreate
          departments={departments}
          open={open}
          onHandleModal={onHandleModal}
          onCreateDepartment={onCreateDepartment}
          onCreateClinic={onCreateClinic}
        />
        {selectedDepart && (
          <EditDepartmentModal
            token={idToken}
            department={selectedDepart}
            open={openEdit}
            onHandleModal={handleModalEdit}
            onEditDepartment={onEditDepartment}
          />
        )}
      </div>
    </>
  );
}

export default DepartmentHospital;
