import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "../fonts/vsf_fonts";
import dayjs from "dayjs";
import { COMPLETE } from "../../constants/referralStatus";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
};

export const printAppointment = (referral, appointmentId) => {
  const appointment = referral?.appointments?.find(
    (a) => a._id === appointmentId
  );

  const dateToLocalDateString = (date) => {
    if (!date || new Date(date).toString() === "Invalid Date") return "";
    return new Date(date).toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const dateToLocalTimeString = (date) => {
    if (!date || new Date(date).toString() === "Invalid Date") return "";
    return new Date(date).toLocaleTimeString("th-TH");
  };

  const expireDate = (referral) => {
    if (referral.data.expireDate) {
      return dateToLocalDateString(referral.data.expireDate?.toString());
    }
    if (referral.data.referDateTime) {
      const dateOfDayjs = dayjs(referral.data.referDateTime).add(3, "months");
      return `${dateToLocalDateString(dateOfDayjs?.toString())} (อัตโนมัติ)`;
    }
    return "-";
  };

  const splitClinic = (point) => {
    const splits = point.split("คลินิก");
    if (splits.length === 0) return "";
    return splits[0];
  };

  const pdfContent = (referral) => {
    return [
      {
        text: "ใบนัดส่งตัว",
        margin: [0, 0, 0, 10],
        style: ["header", "center"],
        pageOrientation: "portrait",
      },
      {
        columns: [
          {
            text: [{ text: "จาก " }, referral.data.fromHospName],
            style: "normalText",
          },
          {
            text: [{ text: "ถึง " }, referral.data.toHospName],
            style: "normalText",
          },
          {
            text: [{ text: "เลขที่ใบส่งตัว " }, referral.data?.referNumber],
            style: "normalText",
          },
        ],
      },
      {
        layout: "headerLineOnly",
        table: {
          widths: ["*", "*"],
          body: [
            [
              {
                text: [
                  {
                    text: "วันที่ส่งตัว ",
                    width: "auto",
                  },
                  dateToLocalDateString(
                    referral.data.referDateTime?.toString()
                  ),
                  " ",
                  dateToLocalTimeString(
                    referral.data.referDateTime?.toString()
                  ),
                  " น.",
                ],
                style: "normalText",
              },
              {
                text: [
                  {
                    text: "สิ้นสุดประสาน ",
                    width: "auto",
                  },
                  expireDate(referral),
                ],
                style: "normalText",
              },
            ],
          ],
        },
      },
      {
        layout: "headerLineOnly",
        table: {
          widths: ["*", "*"],
          body: [
            [
              {
                text: [
                  {
                    text: "เลขบัตรประชาชน",
                    width: "auto",
                  },
                  " ",
                  {
                    text: referral?.data?.cid,
                    style: "bold",
                    width: "auto",
                  },
                ],
                style: "normalText",
              },
              {
                text: [
                  {
                    text: "ชื่อ-สกุล ",
                    width: "auto",
                  },
                  referral?.data?.ptname,
                ],
                style: "normalText",
              },
            ],
          ],
        },
      },
      {
        layout: "headerLineOnly",
        table: {
          widths: [180],
          body: [
            [
              {
                text: [
                  {
                    text: "HN ",
                    width: "auto",
                  },
                  referral?.data?.hn,
                ],
                style: "normalText",
              },
            ],
          ],
        },
        margin: [0, 0, 0, 10],
      },
      appointment?.appointmentDateTime &&
        referral.status === COMPLETE && {
          layout: {
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? "black"
                : "white";
            },
            hLineColor: function (i) {
              return i === 2 && "white";
            },
          },
          table: {
            alignment: "center",
            widths: ["*"],
            body: [
              [
                {
                  text: `วันเวลานัด: ${new Date(
                    appointment?.appointmentDateTime
                  ).toLocaleDateString("th-TH", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })} น.`,
                  style: "bold",
                },
              ],
              [
                {
                  text: `จุดนัดหมาย: ${splitClinic(
                    appointment?.appointmentPoint || "-"
                  )}`,
                  style: "bold",
                },
              ],
              [
                {
                  text: `นัดพบแพทย์: ${appointment?.appointmentDoctor || "-"}`,
                  style: "bold",
                },
              ],
              [
                {
                  text: `หมายเหตุ: ${appointment?.appointmentNotes || "-"}`,
                  style: "bold",
                },
              ],
            ],
          },
        },
    ];
  };

  const docDefinition = {
    pageMargins: [20, 40, 20, 20],
    content: pdfContent(referral),
    defaultStyle: {
      font: "THSarabunNew",
      fontSize: 12,
    },
    styles: {
      logoText: { bold: true, fontSize: 10, margin: [10, 0, 0, 0] },
      header: { bold: true, alignment: "center", fontSize: 16 },
      bold: { bold: true, fontSize: 16 },
      center: { alignment: "center" },
      right: { alignment: "right" },
      marginTop: { margin: [0, 5, 0, 0] },
      normalText: { fontSize: 14 },
      marginLeft: { margin: [5, 0, 0, 0] },
      marginTopSpace: { margin: [0, 80, 0, 0] },
      tabLeft: { margin: [60, 0, 0, 0] },
    },
    pageSize: "A5",
    pageOrientation: "portrait",
  };

  pdfMake.createPdf(docDefinition).open();
};
