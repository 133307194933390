import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "../fonts/vsf_fonts";
import dayjs from "dayjs";

import { checkboxUrl } from "../dataUrl";
import { REFER_BACK, REFER_OUT } from "../../constants/referType";
import { COMPLETE } from "../../constants/referralStatus";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
};
// patient, visit, referral
export const printReferralCBN = ({
  patient,
  visitOPD,
  visitIPD,
  referrals,
  drugs,
  stamp,
}) => {
  function dateToLocalDateString(date) {
    if (!date || new Date(date).toString() === "Invalid Date") return "";
    return new Date(date).toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  function dateToLocalTimeString(date) {
    if (!date || new Date(date).toString() === "Invalid Date") return "";
    return new Date(date).toLocaleTimeString("th-TH");
  }
  const expireDate = (referral) => {
    const expDateString = new Date(
      referral?.data?.expireDate
    ).toLocaleDateString("th-TH");
    const expReferDateString = new Date(
      referral?.data?.referDateTime
    ).toLocaleDateString("th-TH");

    if (
      new Date(expDateString).getTime() ===
        new Date(expReferDateString).getTime() &&
      referral.data.fromHospCode === "10662"
    ) {
      const dateOfDayjs = dayjs(referral.data.referDateTime).add(3, "months");
      return `${dateToLocalDateString(dateOfDayjs?.toString())} (อัตโนมัติ)`;
    }

    if (referral.data.expireDate) {
      return dateToLocalDateString(referral.data.expireDate?.toString());
    }

    if (referral.data.referDateTime) {
      const dateOfDayjs = dayjs(referral.data.referDateTime).add(3, "months");
      return `${dateToLocalDateString(dateOfDayjs?.toString())} (อัตโนมัติ)`;
    }
    return "-";
  };
  function calculateAge(dob) {
    if (!dob || new Date(dob).toString() === "Invalid Date") return "";
    const age =
      new Date().getMonth() > new Date(dob).getMonth()
        ? new Date().getFullYear() - new Date(dob).getFullYear()
        : new Date().getFullYear() - new Date(dob).getFullYear() - 1;
    return age;
  }
  const visitNeedPrint = (referType = REFER_OUT, visitOPD, visitIPD) => {
    if (referType === REFER_BACK && visitIPD) return visitIPD;
    return visitOPD;
  };
  const phoneChonburi = (hospCode, hospName) => {
    if (hospCode !== "10662") return hospName;
    return `${hospName} (038931000)`;
  };

  const splitAppointmentPoint = (appointmentPoint) => {
    const arr = appointmentPoint.split("คลินิก");
    if (arr.length === 0) return "-";
    return arr[0];
  };

  const textForChonburi = (pttype, hospmain, referral) => {
    if (Number(pttype) === 200 || Number(pttype) === 213) {
      return {
        text: `
        ผู้ป่วยในเรียกเก็บ: สปสช. \n ผู้ป่วยนอกเรียกเก็บ: รพ.ชลบุรี \n กรณีมะเร็งเรียกเก็บ CA Anywhere \n ใบส่งตัว: กรณีผู้ป่วยใน ใช้ได้เฉพาะครั้งนี้ \n กรณีผู้ป่วยนอก ใช้ได้ถึง ${expireDate(
          referral
        )} \n **หมายเหตุ รับรองเฉพาะยาในบัญชียาหลักแห่งชาติ`,
      };
    }

    if (Number(pttype) > 200 && Number(pttype) <= 299) {
      return {
        text: `
        ผู้ป่วยในเรียกเก็บ: สปสช. \n ผู้ป่วยนอกเรียกเก็บ: กรณีอื่นเรียกเก็บ  ${
          hospmain || "รพ.ตามสิทธิ"
        } \n กรณีมะเร็งเรียกเก็บ CA Anywhere \n ใบส่งตัว: กรณีผู้ป่วยใน/นอก ใช้ได้เฉพาะครั้งนี้ \n **หมายเหตุ รับรองเฉพาะยาในบัญชียาหลักแห่งชาติ`,
      };
    }

    if (Number(pttype) === 300) {
      return {
        text: `นำใบส่งตัวไปติดต่อขอรับเอกสารรับรองค่ารักษาที่ห้องศูนย์สิทธิ์ชั้น 2 ห้องเบอร์ 47`,
      };
    }

    if (Number(pttype) >= 301 && Number(pttype) <= 304) {
      return {
        text: `ผู้ป่วยใน/นอกประสาน  ${hospmain || "รพ.ตามสิทธิ"} `,
      };
    }
    if (Number(pttype) === 900) {
      return {
        text: `ผู้ป่วยในเรียกเก็บ  ${
          hospmain || "รพ.ตามสิทธิ"
        } (กรณี AdjRW มากกว่า 4 เรียกเก็บ กระทรวงสาธารณสุข) \n ผู้ป่วยนอก เรียกเก็บ ${
          hospmain || "รพ.ตามสิทธิ"
        } \n ใบส่งตัว: กรณีผู้ป่วยใน ใช้ได้เฉพาะครั้งนี้ \n กรณีผู้ป่วยนอก ใช้ได้ถึง ${expireDate(
          referral
        )} และอยู่ในระยะเวลาประกัน
        `,
      };
    }
    if (Number(pttype) === 901) {
      return {
        text: `ผู้ป่วยในเรียกเก็บ  ${
          hospmain || "รพ.ตามสิทธิ"
        } (กรณี AdjRW มากกว่า 4 เรียกเก็บ กระทรวงสาธารณสุข) \n ใบส่งตัว: กรณีผู้ป่วยใน/นอก ใช้ได้เฉพาะครั้งนี้ `,
      };
    }

    // other
    return {
      text: `ใช้สิทธิตามพื้นฐานของผู้ป่วย \n *หมายเหตุ รับรองเฉพาะยาในบัญชียาหลักแห่งชาติ`,
    };
  };

  const checkStampAndTextCbn = (fromHospCode, approveStatus, reason) => {
    return (
      fromHospCode === "10662" &&
      approveStatus &&
      ["เกินศักยภาพ", "ฉายแสง", "เคมีบำบัด"].includes(reason)
    );
  };

  const checkShouldStampInPageOne = (preDiag) => preDiag.length > 1400;

  var docDefinition = {
    content:
      referrals?.length > 0
        ? referrals?.map((referral, indexRefer) => {
            const visit = visitNeedPrint(referral.type, visitOPD, visitIPD);
            const fromHospPhone = phoneChonburi(
              referral.data?.fromHospCode,
              referral.data?.fromHospName
            );
            const toHospPhone = phoneChonburi(
              referral.data?.toHospCode,
              referral.data?.toHospName
            );
            return [
              {
                text: "แบบสำหรับส่งตัวผู้ป่วยไปรับการตรวจหรือรักษาต่อ",
                style: ["header", "center"],
                pageOrientation: "portrait",
                pageBreak: indexRefer > 0 && "before",
              },
              {
                columns: [
                  {
                    text: [{ text: "จาก รพ. " }, fromHospPhone],
                    style: "normalText",
                  },
                  {
                    text: [{ text: "ถึง รพ. " }, toHospPhone],
                    style: "normalText",
                  },
                  {
                    text: [
                      { text: "เลขที่ใบส่งตัว " },
                      referral.data?.referNumber,
                    ],
                    style: "normalText",
                  },
                ],
              },
              // patient info
              {
                layout: "headerLineOnly", // optional
                table: {
                  widths: [150, 100, 200],
                  body: [
                    [
                      {
                        text: [
                          {
                            text: "วันที่ส่งตัว ",
                            width: "auto",
                          },
                          dateToLocalDateString(
                            referral.data.referDateTime?.toString()
                          ),
                        ],
                        style: "normalText",
                      },
                      {
                        text: [
                          { text: "เวลา ", width: "auto" },
                          dateToLocalTimeString(
                            referral.data.referDateTime?.toString()
                          ),
                          "  น.",
                        ],
                        style: "normalText",
                      },
                    ],
                  ],
                },
              },
              {
                layout: "headerLineOnly", // optional
                table: {
                  widths: [150, 180, 100],
                  body: [
                    [
                      {
                        text: [
                          {
                            text: "HN ",
                            width: "auto",
                          },
                          referral?.data?.hn,
                        ],
                        style: "normalText",
                      },
                      {
                        text: [
                          {
                            text: "ชื่อ-สกุล   ",
                            width: "auto",
                          },
                          referral?.data?.ptname ||
                            `${patient?.data?.fullname?.title} ${patient?.data?.fullname?.firstName}  ${patient?.data?.fullname?.lastName}  `,
                        ],
                        style: "normalText",
                      },
                      {
                        text: [
                          { text: "อายุ ", width: "auto" },
                          calculateAge(patient?.data?.DOB),
                          "  ปี ",
                          { text: " เพศ ", width: "auto" },
                          patient?.data.sexName,
                        ],
                        style: "normalText",
                      },
                    ],
                  ],
                },
              },
              referral?.appointments?.length > 0 && referral.status === COMPLETE
                ? {
                    layout: {
                      vLineColor: function (i, node) {
                        return i === 0 || i === node.table.widths.length
                          ? "black"
                          : "white";
                      },
                      hLineColor: function (i) {
                        return i > 0 && i % 2 === 1 && "white";
                      },
                    }, // optional
                    table: {
                      widths: [250, 250],
                      body:
                        referral?.appointments?.length === 0
                          ? []
                          : referral?.appointments
                              .map((appointment) => {
                                return [
                                  [
                                    {
                                      text: `วันเวลานัด: ${new Date(
                                        appointment?.appointmentDateTime
                                      ).toLocaleDateString("th-TH", {
                                        hour: "numeric",
                                        minute: "numeric",
                                        second: "numeric",
                                        weekday: "long",
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                      })}`,
                                      style: "bold",
                                    },
                                    {
                                      text: `จุดนัดหมาย: ${
                                        splitAppointmentPoint(
                                          appointment?.appointmentPoint
                                        ) || "-"
                                      }`,
                                      style: "bold",
                                    },
                                  ],
                                  [
                                    {
                                      text: `นัดพบแพทย์: ${
                                        appointment?.appointmentDoctor?.replace(
                                          "undefined",
                                          ""
                                        ) || "-"
                                      }`,
                                      style: "bold",
                                    },
                                    {
                                      text: `หมายเหตุ: ${
                                        appointment?.appointmentNotes || "-"
                                      }`,
                                      style: "bold",
                                    },
                                  ],
                                ];
                              })
                              ?.flat(),
                    },
                  }
                : {},

              {
                columns: [
                  {
                    text: [
                      {
                        text: "เลขบัตรประชาชน",
                        width: "auto",
                      },
                      "  ",
                      {
                        text: patient?.cid,
                        style: "bold",
                        width: "auto",
                      },
                    ],
                    style: "normalText",
                  },
                  {
                    text: [
                      {
                        text: "(กรณีไม่เคยมีบัตร)",
                        style: "normalText",
                        width: "auto",
                      },
                    ],
                  },
                ],
              },
              // สิทธิการรักษา
              {
                columns: referral?.data?.pttypeName // check exist pttypeName
                  ? [
                      {
                        text: "สิทธิการรักษา",
                        style: "normalText",
                        width: 60,
                      },
                      { text: referral?.data?.pttypeName, style: "normalText" },
                    ]
                  : [
                      {
                        text: "สิทธิการรักษา",
                        style: "normalText",
                        width: 60,
                      },
                      { image: checkboxUrl(), width: 18, height: 14 },
                      {
                        text: "UC",
                        style: "normalText",
                        width: 80,
                      },
                      { image: checkboxUrl(), width: 18, height: 14 },
                      {
                        text: "ประกันสังคม",
                        style: "normalText",
                        width: 80,
                      },
                      { image: checkboxUrl(), width: 18, height: 14 },
                      {
                        text: "ชำระเงิน",
                        style: "normalText",
                        width: 80,
                      },
                      { image: checkboxUrl(), width: 18, height: 14 },
                      {
                        text: "ผู้พิการ",
                        style: "normalText",
                        width: 80,
                      },
                    ],
              },
              {
                columns: [
                  {
                    text: [
                      {
                        text: `ที่อยู่ บ้านเลขที่ ${
                          patient?.data?.address?.houseNumber || "-"
                        } `,
                      },
                      {
                        text: patient?.data?.address?.text || "",
                      },
                    ],
                    style: "normalText",
                  },
                  {
                    text: [
                      { text: "เบอร์โทร  " },
                      { text: patient?.data?.contactInfo?.mobileNumber || "-" },
                    ],
                    style: "normalText",
                  },
                ],
              },
              {
                columns: referral?.data?.pttypeName // check exist pttypeName
                  ? []
                  : [
                      {
                        text: "",
                        width: 60,
                      },
                      { image: checkboxUrl(), width: 18, height: 14 },
                      {
                        text: "เบิกจ่ายตรง",
                        style: ["normalText"],
                        width: 80,
                      },
                      { image: checkboxUrl(), width: 18, height: 14 },
                      {
                        text: "อื่นๆ........................................................................................",
                        style: "normalText",
                        width: 220,
                      },
                    ],
                style: "marginTop",
              },
              // Screen Triage
              {
                columns: [
                  {
                    text: "ภาวะแพ้",
                    style: "bold",
                    width: 300,
                  },
                  // patient drugAllergies

                  {
                    stack: [
                      patient?.data?.drugAllergies?.map((allergy) => ({
                        text: allergy?.genericname,
                        style: "normalText",
                        width: 300,
                      })),
                      checkStampAndTextCbn(
                        referral?.data?.fromHospCode,
                        referral?.approveStatus,
                        referral?.data?.reason
                      ) &&
                      checkShouldStampInPageOne(referral?.data?.preDiagnosis)
                        ? {
                            image: stamp,
                            width: 160,
                            height: 120,
                            margin: [70, 0, 0, -100],
                            alignment: "center",
                            opacity: 0.3,
                          }
                        : null,
                    ],
                  },
                ],
              },
              {
                text: "อาการสำคัญ",
                style: "bold",
              },
              // cc
              {
                text: visit?.visitVital?.cc || "-",
                style: "normalText",
              },
              // hpi
              {
                text: "ประวัติการเจ็บป่วยในอดีต",
                style: "bold",
              },
              {
                text: visit?.visitVital?.hpi || " - ",
                style: "normalText",
              },
              // prediag
              {
                text: "Progress Note",
                style: "bold",
              },
              {
                text: referral?.data?.preDiagnosis || "-",
                style: "normalText",
              },
              // diagnosis
              {
                text: [
                  {
                    text: "Diagnosis",
                    style: "bold",
                    width: "auto",
                    pageBreak:
                      referral?.data?.preDiagnosis?.length > 1800 &&
                      referral?.data?.preDiagnosis?.length < 2500 &&
                      "before",
                  },
                ],
              },

              visit?.diagnoses?.map((diagItem) => ({
                text: `${diagItem.icd10}: ${diagItem.icd10Name || "-"}  ${
                  diagItem.diagTypeName
                } \n`,
                style: "normalText",
              })),
              // การรักษาที่ได้รับ
              {
                text: [
                  {
                    text: "การรักษาที่ได้รับ",
                    style: ["bold", "marginTop", "head"],
                    width: "auto",
                  },
                ],
              },
              drugs?.map((drug, index) => ({
                columns: [
                  {
                    text: `${index + 1}: ${drug?.drugNondugFullName || "-"}`,
                    width: 300,
                    style: "normalText",
                  },
                  {
                    text: dateToLocalDateString(drug?.rxDateTime),
                    style: ["center", "normalText"],
                  },
                ],
              })),
              {
                text: "อาการปัจจุบัน",
                style: "bold",
              },
              // screen
              {
                columns: [
                  {
                    text: [
                      { text: "TEMP= ", style: "normalText" },
                      `${visit?.visitVital?.temperature || "-"} °C`,
                    ],
                    width: 120,
                  },
                  {
                    text: [
                      { text: "PULSE= ", style: "normalText" },
                      `${visit?.visitVital?.pulse || "-"} /min.`,
                    ],
                    width: 120,
                  },
                  {
                    text: [
                      { text: "RR= ", style: "normalText" },
                      `${visit?.visitVital?.rr || "-"} /min`,
                    ],
                    width: 120,
                  },
                  {
                    text: [
                      { text: "BP= ", style: "normalText" },
                      `${visit?.visitVital?.bps} / ${visit?.visitVital?.bpd} mmHg`,
                    ],
                    width: 120,
                  },
                ],
                style: "marginTop",
              },
              {
                columns: [
                  {
                    text: [
                      { text: "FBS= ", style: "normalText" },
                      `${visit?.visitVital?.fbs || "-"}`,
                    ],
                    width: 120,
                  },
                  {
                    text: [
                      { text: "WAIST= ", style: "normalText" },
                      `${visit?.visitVital?.waist || "-"}`,
                    ],
                    width: 120,
                  },
                  {
                    text: [
                      { text: "HEIGHT= ", style: "normalText" },
                      `${visit?.visitVital?.height || "-"}`,
                    ],
                    width: 120,
                  },
                  {
                    text: [
                      { text: "BW= ", style: "normalText" },
                      `${visit?.visitVital?.bw || "-"}`,
                    ],
                    width: 120,
                  },
                ],
              },

              //  สาเหตุที่ส่ง

              {
                text: "สาเหตุที่ส่ง",
                style: "bold",
                pageBreak:
                  referral?.data?.preDiagnosis?.length > 1200 &&
                  referral?.data?.preDiagnosis?.length < 1600 &&
                  "before",
              },
              {
                text: referral?.data?.reason || "-",
                style: "normalText",
              },
              {
                columns: checkStampAndTextCbn(
                  referral?.data?.fromHospCode,
                  referral?.approveStatus,
                  referral?.data?.reason
                )
                  ? [
                      {
                        text: [
                          textForChonburi(
                            referral?.data?.pttype,
                            visit?.hospmainName,
                            referral
                          ),
                          `\n ${
                            referral?.approveName ||
                            "..........................."
                          } (เจ้าหน้าที่ประสานการรับและส่งต่อผู้ป่วย 038931050,038931973)`,
                        ],
                        width: 300,
                        style: "normalText",
                      },

                      {
                        stack: [
                          {
                            image: stamp,
                            width: 160,
                            height: 120,
                            margin: [70, 0, 0, -100],
                            alignment: "center",
                            opacity: 0.3,
                          },
                          {
                            text: [
                              {
                                text: `ลงชื่อ............................................................................... \n ${
                                  `.......................${referral?.data?.doctorName}.......................` ||
                                  "........................................................."
                                } `,
                              },
                            ],
                            style: ["normalText", "right"],
                          },
                        ],
                      },
                    ]
                  : [
                      {
                        text:
                          referral?.data?.fromHospCode !== "10662"
                            ? `ผู้ป่วยนอกใช้ 90 วัน ผู้ป่วยในใช้ได้ 60 วัน \n ผู้ป่วยในเรียกเก็บ สปสช. \n ผู้ป่วยนอกเรียกเก็บ รพ. .......................................................... \n วันส่งตัวใช้ได้ถึง ${expireDate(
                                referral
                              )} \n คุ้มครองสิทธิเฉพาะยาในบัญชีหลักแห่งชาติ`
                            : "",
                      },
                      {
                        text: [
                          {
                            text: `ลงชื่อ ............................................................................... \n ${
                              `.......................${referral?.data?.doctorName}.......................` ||
                              "........................................................."
                            } `,
                          },
                        ],
                        style: ["normalText", "right"],
                      },
                    ],
                style: ["marginTop"],
              },
              {},
            ];
          })
        : [],

    defaultStyle: {
      font: "THSarabunNew",
      fontSize: 12,
    },
    styles: {
      logoText: { bold: true, fontSize: 10, margin: [10, 0, 0, 0] },
      header: { bold: true, alignment: "center", fontSize: 16 },
      bold: { bold: true, fontSize: 16 },
      center: { alignment: "center" },
      right: { alignment: "right" },
      marginTop: { margin: [0, 5, 0, 0] },
      normalText: { fontSize: 14 },
      marginLeft: { margin: [5, 0, 0, 0] },
      marginTopSpace: { margin: [0, 80, 0, 0] },
      tabLeft: { margin: [60, 0, 0, 0] },
    },
    pageSize: "A4",
    pageOrientation: "portrait",
  };
  pdfMake.createPdf(docDefinition).open();
};
