import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";

interface IAccordion {
  title: string;
  word: string;
  isExpand: boolean;
  onChange(event: React.SyntheticEvent<Element, Event>): void;
}
function AccordionReferral({ title, word, isExpand, onChange }: IAccordion) {
  const splitWord = () =>
    word.split("\r").map((item, index) => (
      <div key={index}>
        <h6>{`${index + 1}. ${item}`}</h6>
      </div>
    ));

  return (
    <Box>
      <Divider sx={{ my: 2 }}>{title}</Divider>

      <Accordion onChange={(event) => onChange(event)} expanded={isExpand}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{splitWord()}</AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default AccordionReferral;
