import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

export default async (
  token: string,
  action: string,
  referralBid: string,
  visitBid: string
) => {
  try {
    const url = `${getApiUrl()}/v1/mophRefer/retry`;
    const response = await axios.post(
      url,
      { action, referralBid, visitBid },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(
        error.response.data.message_th ||
          error.response.data.message ||
          "เกิดข้อผิดพลาด โปรดลองอีกครั้ง"
      );
    }
    throw new Error("เกิดข้อผิดพลาด โปรดลองอีกครั้ง");
  }
};
